.list {
  display: flex;
  flex-direction: column;
  row-gap: 8px;
  margin-bottom: 8px;

  &:has([data-empty='true']) {
    height: 100%;
  }
}

.customer {
  padding: 10px;
  border-radius: 10px;
  background-color: var(--gray-op40);
}
