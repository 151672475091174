.avatar {
  margin-left: auto;
  margin-right: auto;
}

.label {
  margin-bottom: 5px;
  font-size: 14px;
  color: var(--gray);
}

.input {
}

.saveButton {
}

.cancelButton {
  width: 100%;
  color: var(--gray);
  text-align: center;
}

