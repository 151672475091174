.container {
  flex: 1;
  text-align: center;
  display: flex;
  flex-direction: column;

  > * {
    flex: 1;
    margin: 0 auto;
  }
}

.hello {
  margin-top: 3rem;
  width: 6rem;
}

.heading {
  margin-top: 6rem;
  font-weight: bolder;
  font-size: 36px;
}

.logo {
  flex: 0;
  width: 5rem;
  margin-top: 10%;
}

.slogan {
  flex: 0.5;
  font-weight: bold;
  font-size: 18px;
}

.skip {
  margin-top: 30px;
  margin-bottom: 30px;
  text-decoration: underline;
  cursor: pointer;
}

.buttonContainer {
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
  margin: 2em auto;
}

.button span {
  display: flex;
  flex-wrap: nowrap;
}

.active {
  color: var(--blue);
  text-decoration: underline;
  cursor: pointer;
}

.code {
  color: var(--green);
  font-size: 35px;
  font-weight: 700;
}

.list {
  color: var(--gray);
}

.listItem {
  font-size: 18px;
  font-weight: 500;
  line-height: 1.3;
}

.loginButton {
  margin-top: 2em;
  margin-bottom: 2em;
}

.tagIcon {
  color: var(--blue);
  margin-bottom: -4px;
  margin-right: 4px;
}

.arrowIcon {
  color: var(--blue);
  margin-left: 10px;
  margin-right: 10px;
}

.installButton {
  border-radius: 40px;
  font-weight: 700;
}
