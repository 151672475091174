.container {
  padding: 8px 12px;
  background: var(--white-op20);
  width: fit-content;
  border-radius: 8px;
  display: flex;
  align-items: center;
  gap: 8px;
}

.currency {
  color: var(--white);
  margin-right: 4px;
  width: auto;
}

.currencyContainer {
  display: flex;
  align-items: center;
}

.input {
  border: none;
  display: inline;
  font-family: inherit;
  font-size: inherit;
  max-width: 100%;
  padding: 0;
}
