.empty {
  display: flex;
  flex-direction: column;
  row-gap: 44px;
}

.skeleton {
  border-radius: 12px;
}

.capabilities {
  display: flex;
  flex-direction: column;
  margin-top: 24px;
  color: var(--white);
}

.banner {
  margin-top: 44px;

  + .capabilities {
    margin-top: 20px;
  }
}

.iconsList {
  display: flex;
  justify-content: space-around;
  flex-wrap: wrap;
  margin-top: 20px;
}

.iconPercent {
  width: 20px;
}

.balanceSummary > div:last-child {
  margin-bottom: 0;
}

.balanceSummary {
  margin-top: 20px;
}

.aiIcon {
  margin-right: 20px;
}

.iconHistory {
  height: 40px;
}

.noProject {
  margin-top: 24px;
  text-align: center;
  color: var(--white-op60);
}

.iconBell {
  color: var(--blue);
  margin: 0 20px 0 5px;
}

.iconCrm {
  color: var(--blue);
  margin: 0 20px 0 5px;
  width: 20px;
}

.iconMan {
  color: var(--blue);
  margin: 0 20px 0 6px;
  width: 19px;
}

.logoutText {
  color: var(--red);
}
