.container {
  background: linear-gradient(to bottom, rgba(40, 40, 40, 0.7), rgba(25, 25, 25, 0.7));
  border-radius: 12px;
  padding: 18px;
  border: 1px solid var(--gray3);
  width: 100%;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.2);
  color: var(--white);
}

.title {
  font-size: 18px;
  font-weight: 600;
  margin-bottom: 12px;
  color: var(--white);
}

.description {
  margin-bottom: 20px;
  color: var(--gray5);
  font-size: 14px;
  line-height: 1.5;
}

.fileUpload {
  display: flex;
  flex-direction: column;
  gap: 16px;
}

.fileInput {
  display: none;
}

.fileControls {
  display: flex;
  flex-wrap: wrap;
  gap: 12px;
  align-items: center;
}

.selectButton {
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 12px 16px;
  border: 1px solid var(--gray3);
  border-radius: 8px;
  background-color: var(--gray2);
  cursor: pointer;
  font-size: 14px;
  min-height: 48px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  color: var(--white);
  transition: background-color 0.2s, border-color 0.2s;
  flex-grow: 1;
  min-width: 200px;
  &:hover {
    background-color: var(--gray3);
    border-color: var(--gray4);
  }
}

.uploadButton {
  padding: 12px 20px;
  background-color: var(--blue);
  color: var(--white);
  border: none;
  border-radius: 8px;
  font-size: 14px;
  min-height: 48px;
  transition: background-color 0.2s;
  flex-shrink: 0;
  &:hover:not(:disabled) {
    background-color: var(--blue-hover);
  }
  &:disabled {
    opacity: 0.7;
    cursor: not-allowed;
  }
} 