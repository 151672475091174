.empty {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
}

.assistants {
  display: flex;
  flex-direction: column;
  row-gap: 16px;
  margin-bottom: 30px;
}

.loading {
  display: flex;
  flex-wrap: nowrap;
  gap: 16px;
}

.header {
  font-size: 20px;
  font-weight: 600;
  margin-bottom: 15px;
}

.balance {
  margin-bottom: 15px;
}

.balanceTitle {
  font-size: 20px;
  font-weight: 600;
}
