.wrap {
  display: flex;
  flex-direction: column;
  width: 100%;
}

.providers {
  display: flex;
  column-gap: 10px;
  padding: 10px 0;
  overflow-x: auto;
}

.provider,
.newProvider {
  border: 3px solid transparent;
  border-radius: var(--border-round);
  transition: 300ms;
}

@for $i from 1 through 4 {
  .divider#{$i}:nth-child(#{$i}) {
    position: relative;
    margin-right: 23px;

    &::after {
      position: absolute;
      height: 22px;
      width: 2px;
      right: -20px;
      top: 50%;
      content: '';
      background-color: var(--white);
      z-index: 1;
      border-radius: 5px;
      padding: 2px;
      transform: translate(+50%, -50%);
    }
  }
}
