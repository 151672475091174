@import 'src/app/styles/vars';

$side: 26px;

.nav {
  @include content-container();
  position: fixed;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 100;
  width: 100%;

  &:after {
    position: absolute;
    top: 50%;
    left: 50%;
    z-index: -1;
    width: 100vw;
    height: 100%;
    transform: translate(-50%, -50%);
    background: #000000;
    content: '';
  }
}

.links {
  display: flex;
  justify-content: space-around;
  padding: 12px 0 32px;
  font-size: 10px;
  line-height: 12px;
  color: var(--white-op20);
}

.link {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 48px;
  cursor: pointer;
  color: inherit;
  text-decoration: none;
}

.active,
.link:hover {
  .icon,
  .title {
    color: var(--white);
  }

  .circle {
    background: var(--white);
  }
}

.icon {
  display: flex;
  align-items: center;
  justify-content: center;
  min-height: $side;
}

.title {
  margin-top: 6px;

  &:first-letter {
    text-transform: uppercase;
  }
}
