.assistant {
  display: flex;
  align-items: center;
  justify-content: space-between;
  border-radius: 10px;
  background-color: #121212;
  padding: 7px 10px;
}

.iconContainer {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 20px;
  height: 20px;
  cursor: pointer;
}

.removeAssistantIconContainer {
  border-radius: var(--border-round);
  background: var(--gray3);
}

.addAssistantIconContainer {
  box-sizing: border-box;
  padding: 5px;
}

.removeAssistantIcon {
  stroke: var(--black);
}

.addAssistantIcon {
  fill: var(--gray3);
} 