.row {
  display: flex;
  flex-wrap: nowrap;
  width: 100%;
}

.titleWrap {
  margin-left: 6px;
  height: 25px;
  line-height: 25px;
}

.title {
  color: var(--white);
}

.with {
  font-size: 10px;
  font-weight: 600;
}

.iconContainer {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 20px;
  height: 20px;
  border-radius: var(--border-round);
  margin-top: 2px;
  margin-left: 5px;
  background-color: rgba(82, 119, 253, 0.23);
  color: #4866EC;
}

.updatePriorityIconContainer {
  margin-left: auto;
  margin-right: 0;
}

.archiveIconContainer {
  background: var(--gray-op40);
  color: var(--yellow-op50);
}

.removeIconContainer {
  background: var(--gray-op40);
  stroke: var(--red);
}

.arrowUpIcon {
  width: 8px;
  height: 11px;
}

.archiveIcon {
  width: 16px;
  height: 11px;
}

.historyIcon {
  width: 15px;
  height: 15px;
}

.assistantIcon {
  width: 16px;
  height: 12px;
}
