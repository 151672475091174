.list {
  display: flex;
  justify-content: space-between;
  max-width: 254px;
  width: 100%;
  margin: 0 auto 20px;
}

.item {
  max-width: 74px;
  width: 100%;
}

.btn {
  padding: 5px 10px;
  font-size: 10px;
  line-height: 25px;
}
