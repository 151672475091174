.form {
  display: flex;
  flex-direction: column;
}

.values {
  display: flex;
  color: var(--white);
  border: 1px solid;
  border-radius: var(--border-radius);
  padding: 8px 0;
  margin-bottom: 10px;
}

.amount,
.code {
  padding: 0 8px;
  border-radius: initial;
  color: var(--white);
  font-size: 26px;
  line-height: 30px;
}

.amount,
.code,
.close {
  border: none;
}

.amount,
.code,
.comment,
.button {
  outline: none;
  background: transparent;
}

.amountWrap {
  flex: 3;
}

.codeWrap {
  flex: 1.5;
}

.code {
  border-left: 1px solid var(--white);
  caret-color: var(--white);
}

.comment {
  padding: 10px;
  min-height: 80px;
  resize: vertical;
  border-radius: var(--border-radius);
  color: var(--white);
  font-family: inherit;
  font-size: 16px;
  line-height: 18px;
  transition: border 300ms;
  border: 1px solid var(--gray1);
  background: var(--gray1);
}

.error {
  color: var(--red);
  margin-top: 1em;
}

.buttons {
  margin-top: 30px;
  display: flex;
  flex-direction: column;
}

.button {
  padding: 6px;
  cursor: pointer;
}

.submit {
  background: var(--white);
  color: var(--black);
  margin-bottom: 16px;
  border: 0;
  border-radius: var(--border-radius);
  font-size: 26px;
  line-height: 30px;
  font-weight: 600;
  transition: 300ms;

  &:disabled {
    color: var(--white);
    background: var(--gray1);
  }
}

.close {
  align-self: center;
  color: var(--gray);
  font-size: 20px;
  line-height: 22px;
}
