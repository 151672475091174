.userInfo {
  display: flex;
  flex-direction: row;
  background-color: #222222;
  width: 100%;
  height: 80px;
  border-radius: 28px;
  padding: 16px;
  margin: 2px 0 2px 0;
}

.userPhoto {
  width: 48px;
  height: 48px;
  border-radius: 12px;
  background: #1B1B1B center;
  background-size: cover;
  margin-right: 16px;
}

.userRole {
  font-size: 12px;
  line-height: 16px;
  font-weight: 400;
  color: var(--white);
  opacity: .3;
}

.userName {
  font-size: 16px;
  line-height: 24px;
  font-weight: 400;
  color: var(--white);
}
