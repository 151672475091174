.root {
  display: flex;
  flex-direction: column;
  align-items: center;
  flex: 1;
}

.icon {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 50px;
  height: 50px;

  background-color: var(--bg-black);
  border-radius: 50%;
  color: #D9D9D9;

  margin-bottom: 2px;
}

.text {
  font-size: 12px;
  font-weight: 600;
  text-align: center;
}
