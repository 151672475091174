.guiSwitch {
  position: relative;
  display: inline-block;
  width: 50px;
  height: 28px;
}

.guiSwitch input {
  opacity: 0;
  width: 0;
  height: 0;
}

.slider {
  position: absolute;
  cursor: pointer;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: transparent;
  transition: .4s;
  border-radius: 18px;
  border: 1px solid;
}

.slider:before {
  position: absolute;
  content: "";
  height: 20px;
  width: 20px;
  left: 4px;
  bottom: 3px;
  transition: .4s;
  border-radius: 50%;
  border: 1px solid var(--white);
}

input:checked + .slider {
}

input:checked + .slider:before {
  transform: translateX(21px);
  background: var(--white);
}

