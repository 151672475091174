@import 'src/app/styles/vars';

.header {
  @include content-container();

  display: flex;
  flex-direction: column;
  row-gap: 10px;
  padding-top: 10px;
  padding-bottom: 10px;
}

.unsetRowGap {
  row-gap: 0;
}

.unsetBottomPadding {
  padding-bottom: 0;
}
