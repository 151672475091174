@import 'src/app/styles/vars';

@include tabsStyles();

.wrap {
  position: relative;
  display: flex;
  flex-direction: column;
}

.tabsWrap {
  position: sticky;
  top: -20px;
  right: 0;
  left: 0;
  z-index: 5;
  padding: 10px 0 15px;
  background: var(--black);
}
