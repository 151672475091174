.inline {
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
  gap: 10px;
}

.line {
  width: 100%;
  background: var(--gray);
  height: 1px;
  margin: 16px 0;
}

.text {
  font-size: 12px;
}

.crossExchangeWrap {
  margin-top: 10px;
  margin-bottom: 16px;
}

.navigators {
  display: flex;
  gap: 16px;

  @media screen and (max-width: 414px) {
    flex-direction: column;
  }
}

.copy {
  flex: 1;
}

.telegram {
  flex: 2;
  gap: 8px;
  display: flex;
  align-items: center;
  justify-content: center;
}
