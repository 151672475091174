.card {
  display: flex;
  align-items: center;
  column-gap: 10px;
  padding: 7px 10px;

  border-radius: var(--border-radius);
  background: var(--bg-black);
}

.wrap {
  display: flex;
  flex-direction: column;
  row-gap: 8px;
  width: 100%;
}

.inner {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.balances {
  display: flex;
  align-items: center;
  gap: 10px;

  font-size: 12px;
  line-height: 14px;
}

.balance {
  & .icon {
    color: var(--green);
  }

  &[data-balance-type='send'] {
    color: var(--green);
  }

  &[data-balance-type='receive'] {
    color: var(--red);
  }
}

.username {
  flex: 1;
  overflow: hidden;
  overflow-wrap: anywhere;
  max-height: 2em;
}

.btns {
  display: flex;
  column-gap: 8px;
  margin-left: 8px;
}

.btn {
  padding: 5px 10px;
  font-size: 10px;
  line-height: 12px;
  font-weight: 600;
}

.icon {
  flex-shrink: 0;
}
