.content {
  padding-bottom: 96px;
}

.table {
  background: transparent;

  & > :nth-child(2n) {
    border-bottom: 1px solid var(--gray3);
  }

  & > :last-child {
    border-bottom: none;
    border-radius: 0 0 8px 8px;
  }
}

.emptyPlaceholder {
  text-align: center;
  margin: 10px auto;
  opacity: .5;
}
