.dotIndicator {
  border: 2px solid var(--bg-black);
  padding: 8px;
  top: -2px;
  right: -2px;
}

.provider {
  background: var(--green);
}

.customer {
  background: var(--blue);
}

.customerAndProvider {
  background: linear-gradient(to right, var(--green) 50%, var(--blue) 50%);
}
