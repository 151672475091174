.card {
  padding: 7px 10px;

  border-radius: var(--border-radius);
  background: var(--bg-black);
  min-height: 88px;
}

.ignore {
  filter: opacity(0.5);
}

.cardWrapper {
  display: flex;
  align-items: center;
  column-gap: 10px;
}

.wrap {
  display: flex;
  justify-content: space-between;
  row-gap: 8px;
  width: 100%;
}

.inner {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.text {
  position: relative;
  display: flex;
  flex-direction: column;
  gap: 5px;

  font-size: 12px;
  line-height: 14px;

  margin-top: 10px;

  padding-right: 110px;
}

.date {
  position: absolute;
  right: 0;
  top: 0;
}

.username {
  flex: 1;
  overflow: hidden;
  overflow-wrap: anywhere;
  max-height: 2em;
}

.btns {
  display: flex;
  column-gap: 8px;
  margin-left: 8px;
}

.btn {
  padding: 5px 10px;
  font-size: 10px;
  line-height: 12px;
  font-weight: 600;
}

.icon {
  flex-shrink: 0;
}

.message {
  display: -webkit-box;
  overflow: hidden;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 2;
  line-clamp: 2;
  word-break: break-word;
}

.attention {
  color: var(--red-new)
}
