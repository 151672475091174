.input {
  text-align: right;
  border: none;
  border-radius: 0;
  padding: 14px 20px;
}

.inputWrapper {
  &:first-child {
    border-right: 1px solid var(--gray3);
  }
}
