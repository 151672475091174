.button {
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 20px;
  outline: none;
  cursor: pointer;
  transition: background 300ms;
}

.contained,
.exchanger,
.outlined {
  width: 100%;
  padding: 16px 20px;
  color: var(--white);
  border: 1px solid var(--blue);
  border-radius: 20px;
}

.contained,
.exchanger {
  background: var(--blue);
}

.outlined {
  background: transparent;

  &.active {
    border-color: var(--green);
    background: var(--green);
    color: var(--black);
  }

  &.danger {
    border-color: var(--red);
  }

  &.danger.active {
    border-color: var(--red);
    background: var(--red);
    color: var(--white);
  }
}

.rounded,
.success,
.white {
  padding: 0;
  border-radius: var(--border-radius);
}

.success,
.white {
  color: var(--black);
}

.rounded {
  width: 21px;
  height: 21px;
  background: var(--green-op20);
  color: var(--green);
  border: 1px solid var(--green-op20);
}

.success {
  background: var(--green);
  border: 1px solid var(--green);
}

.white {
  background: var(--white);
  border: 1px solid var(--white);
}

.containedDark,
.contained:disabled {
  background: var(--blue-op20);
}

.containedDark,
.contained:disabled,
.outlined:disabled {
  border-color: var(--blue-op20);
}

$color: rgba(64, 64, 64, 0.35);
.exchanger:disabled,
.rounded:disabled {
  background: $color;
  border-color: $color;
  color: var(--gray);
}

.minimal {
  background: transparent;
  border: none;
  color: var(--blue);
  font-weight: bold;
}

.containedDark {
  width: 100%;
  background: var(--blue-op20);
  border: 1px solid transparent;
  border-radius: var(--border-radius);
  color: var(--blue);

  &.active {
    background: var(--blue);
    color: var(--white);
  }
}

.gray {
  width: 100%;
  font-size: 18px;
  padding: 10px;
  color: var(--white);
  border: none;
  border-radius: var(--border-radius);
  background-color: var(--gray1);
}

.disabled {
  opacity: 0.6;
  cursor: not-allowed;
}

.loading {
  position: relative;
  visibility: hidden;

  &::after {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    height: 100%;
    width: auto;
    content: '';

    border-radius: 50%;
    background-color: var(--blue);
  }
}

.telegram {
  position: relative;
  width: 100%;
  border-radius: 20px;
  /* tg color */
  background-color: var(--telegram);

  margin: 0 auto;

  border: none;
  color: #fff;

  font-family: inherit;
  font-size: 14px;
  font-weight: 500;
  line-height: 16px;
  padding: 14px;

  .icon {
    width: 24px;
    height: 22px;
    margin: 0 13px -2px -7px;
    background: url(data:image/svg+xml,%3Csvg%20height%3D%2224%22%20viewBox%3D%220%200%2024%2024%22%20width%3D%2224%22%20xmlns%3D%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22%3E%3Cpath%20d%3D%22m1.95617055%2011.392196c5.77764656-2.42328736%209.63031585-4.02086673%2011.55800785-4.79273807%205.5039525-2.20384954%206.6476266-2.5866818%207.3930574-2.59932314.1639507-.00278035.5305319.0363352.7679878.22182361.2005031.15662277.2556695.36819788.2820684.51669348.026399.1484956.0592719.48677234.0331404.75109194-.2982611%203.0169019-1.5888322%2010.33812718-2.2454015%2013.71710898-.2778191%201.4297738-.8288514%201.7357846-1.3584441%201.7826999-1.1509274.1019576-2.0208916-.5588425-3.1356211-1.2622918-1.7443316-1.1007592-2.3854935-1.3972358-4.0786694-2.4713734-1.95675765-1.2413519-.8891962-1.8911034.2259543-3.0061212.2918402-.2918054%205.3989024-4.83750096%205.497052-5.24030969.0122753-.05037796-.1557336-.55407742-.2716182-.65323489-.1158847-.09915747-.2869204-.06524947-.4103446-.03828214-.17495.03822537-2.9615423%201.81132342-8.35977698%205.31929412-.79096496.5228681-1.50739646.7776269-2.1492945.7642766-.70764107-.0147176-2.06885864-.3851791-3.08078398-.7018404-1.24116762-.388398-1.69932554-.5713149-1.61342745-1.2309348.04474105-.3435709.36011227-.7024173.94611366-1.0765391z%22%20fill%3D%22%23fff%22%20fill-rule%3D%22evenodd%22%2F%3E%3C%2Fsvg%3E)
      no-repeat 0 -1px;

    display: inline-block;
    vertical-align: top;
  }
}

.whatsapp {
  position: relative;
  width: 100%;
  border-radius: 20px;
  /* tg color */
  background-color: var(--whatsapp);
  margin: 0 auto;

  border: none;
  color: #fff;

  font-family: inherit;
  font-size: 14px;
  font-weight: 500;
  line-height: 16px;
  padding: 16px;
}

.blackWhite {
  background-color: var(--black);
  color: white;
  border-color: white;
  border-radius: 20px;

  font-family: inherit;
  font-size: 14px;
  font-weight: 500;
  line-height: 16px;
  padding: 16px;
}
