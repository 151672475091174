.table {
  width: 100%;
  margin-bottom: 10px;
  border-collapse: collapse;
  font-size: 12px;
  line-height: 15px;
}

.heads tr {
  border-bottom: 1px solid var(--gray2);
}

.heads th {
  padding-bottom: 6px;
  text-align: start;
  color: var(--blue);

  &:first-child {
    padding-left: 8px;
  }

  &:last-child {
    padding-right: 8px;
  }
}
