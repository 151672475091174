// @see https://codepen.io/thebabydino/pen/mRrPwB
$char-width: 1ch;
$gap: 0.5 * $char-width;
$totalChars: 5;
$input-width: $totalChars * ($char-width + $gap);

.inputWithCharUnderline input {
  display: block;
  border: none;
  border-radius: unset;
  margin: 0 auto;
  padding: 0;
  width: $input-width;
  background: repeating-linear-gradient(
      90deg,
      var(--white) 0,
      var(--white) $char-width,
      transparent 0,
      transparent $char-width + $gap
    )
    0 100% / #{$input-width - $gap} 2px no-repeat;
  // TODO: use variable for the font style
  // i.e. something like var(--font-mono)
  font: 5ch source-code-pro, Menlo, Monaco, Consolas, 'Courier New', monospace;
  letter-spacing: $gap;

  &:focus {
    outline: none;
    color: var(--blue);
  }
}
