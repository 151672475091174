.card {
  display: flex;
  column-gap: 20px;
  align-items: center;
  padding: 10px;
}

.info {
  display: flex;
  flex-direction: column;
  width: 100%;
}

.comment {
  color: var(--white);
  word-wrap: break-word;
  max-width: 300px;
}

.additionalInfo {
  color: var(--gray3);
  font-size: 12px;
}

.balanceName {
  color: var(--gray3);
  font-size: 12px;
  max-width: 50px;
  word-wrap: break-word;
}

.amount {
  display: flex;
  flex-shrink: 0;
  flex-direction: column;
  text-align: right;
}

.value {
  &.plus {
    color: var(--green);
  }

  &.minus {
    color: var(--red);
  }
}

.authorName {
  font-size: 10px;
  font-weight: 300;
}

.canceledCard {
  opacity: 0.7;
}

.canceled {
  color: var(--gray);
  text-decoration: line-through;
}

.cancelIcon {
  width: 24px;
  height: 24px;
}

.amountInfo {
  display: flex;
  justify-content: flex-end;
  align-items: center;
  gap: 5px;
}
