@import 'src/app/styles/vars';

@include tabsStyles();

.wrap {
  position: relative;
  display: flex;
  flex-direction: column;
}

.tabsWrap {
  position: sticky;
  top: -20px;
  right: 0;
  left: 0;
  z-index: 5;
  padding: 10px 0 15px;
}

.form {
  display: flex;
  flex-direction: column;
}

.form[data-form-type='send'] {
  .values {
    border-color: var(--white);
  }

  .submit {
    background: var(--white);
    color: var(--black);

    &:disabled {
      color: var(--white);
      background: var(--gray1);
    }
  }
}

.form[data-form-type='receive'] {
  .values {
    border-color: var(--green);
  }

  .submit {
    background: var(--green);
    color: var(--black);

    &:disabled {
      color: var(--green);
      background: var(--green-op20);
    }
  }

  .request {
    background-color: var(--dark-blue);
    color: var(--white);

    &:disabled {
      background-color: var(--blue-op20);
      color: var(--white-op60);
    }
  }
}

.values {
  display: flex;
  color: var(--white);
  border: 1px solid;
  border-radius: var(--border-radius);
  padding: 8px 0;
  margin-bottom: 10px;
}

.amount.amount,
.code {
  padding: 0 8px;
  border-radius: initial;
  color: var(--white);
  font-size: 26px;
  line-height: 30px;
}

.amount.amount,
.code,
.close {
  border: none;
}

.amount.amount,
.code,
.comment,
.button {
  outline: none;
  background: transparent;
}

.amountWrap {
  flex: 3;
}

.codeWrap {
  flex: 1.5;
}

.code {
  border-left: 1px solid var(--white);
  caret-color: var(--white);
}

.comment {
  padding: 10px;
  min-height: 80px;
  resize: vertical;
  border-radius: var(--border-radius);
  color: var(--white);
  font-family: inherit;
  font-size: 16px;
  line-height: 18px;
  transition: border 300ms;
  border: 1px solid var(--gray1);
  background: var(--gray1);
}

.error {
  color: var(--red);
  margin-top: 1em;
}

.buttons {
  margin-top: 30px;
  display: flex;
  flex-direction: column;
}

.button {
  padding: 6px;
  cursor: pointer;
}

.submit {
  margin-bottom: 16px;
  border: 0;
  border-radius: var(--border-radius);
  font-size: 26px;
  line-height: 30px;
  font-weight: 600;
  transition: 300ms;
}

.close {
  align-self: center;
  color: var(--gray);
  font-size: 20px;
  line-height: 22px;
}
