.textarea {
  flex: 1;
  resize: none;
  padding: 5px;
  border: 1px solid #ccc;
  border-radius: 7px;
  outline: none;
  font-size: 16px;
  max-height: 100px;
  overflow-y: auto;
  line-height: 18px;
  transition: height 0.2s ease;
  min-height: 30px;
  height: 30px;
  background-color: transparent;
  color: inherit;
}

.textarea::-webkit-resizer {
  display: none;
}

.secondCrmInputBlock {
  display: flex;
  flex-direction: column;
  gap: 10px;
  position: relative;
  width: 100%;
}

.secondCrmInput {
  display: flex;
  flex-direction: row;
  gap: 10px;
  align-items: flex-end;
}

.button {
  position: relative;
  width: 30px;
  height: 30px;
  min-width: 30px;
  min-height: 30px;
  border-radius: 50%;
  padding: 0;
  transform: rotate(90deg);
}

.arrowIcon {
  position: absolute;
  width: 30px;
  height: 30px;
  left: 50%;
  bottom: 50%;
  transform: translate(-50%, 50%);
}

.contacts {
  display: flex;
  flex-direction: column;
  gap: 5px;
  opacity: 1;
  transition: opacity 0.3s ease-in-out;
}

.list {
  display: flex;
  flex-direction: column;
  gap: 5px;
  overflow-y: scroll;
  max-height: 350px;
  transform: translateY(0);
  opacity: 1;
  position: absolute;
  width: calc(100% + 40px);
  bottom: 40px;
  left: -40px;
  background-color: var(--black);
}

.hide {
  transform: translateY(500px);
  opacity: 0;
  transition: transform 0.5s ease, opacity 0.7s ease;
}
