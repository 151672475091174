.tab {
  border-radius: 8px;
  border: none;
  color: var(--white);
  font-style: normal;
  font-weight: 500;
  font-size: 15px;
  line-height: 120%;
  display: flex;
  align-content: center;
  justify-content: center;
  flex: 1;
  width: 100%;
  padding: 7px 0;
  background: transparent;
}

.selected {
  background: var(--white);
  color: var(--black)
}
