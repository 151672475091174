.slider {
  display: flex;
  column-gap: 5px;
  margin-bottom: 20px;

  [data-selected='true'] {
    border: none;

    > div {
      background-color: var(--dark-blue);
    }

    .text {
      opacity: 1;
    }

    input {
      color: var(--white) !important;
    }

    path {
      stroke-opacity: 1 !important;
      fill-opacity: 1 !important;
    }
  }
}

.slide {
  border: none;
  flex-grow: 1;

  > div {
    display: flex;
    flex-direction: column;
    row-gap: 10px;
  }
}

.sliderNoActive {
  margin-bottom: 10px;

  li {
    border: none;
  }

  .text {
    opacity: 1 !important;
  }

  input {
    color: var(--white) !important;
  }

  path {
    stroke-opacity: 1 !important;
    fill-opacity: 1 !important;
  }
}

.root {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  padding: 16px;
  background-color: #222222;
  border-radius: 20px;

  .rate {
    max-width: 150px;
    padding: 8px;
    margin-right: 0;
    border-radius: 12px;
    border: 1px solid var(--white-op30);

    input {
      color: var(--white-op30);
      font-size: 14px;
    }

    path {
      stroke-opacity: 0.3;
      fill-opacity: 0.3;
    }
  }

  .text {
    font-size: 14px;
    color: var(--white);
    opacity: 0.5;
  }
}
