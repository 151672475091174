.modal {
  background-color: rgba(24, 24, 24, 0.5);
}

.modalContent {
  background-color: var(--black);
  border-radius: 20px;
  max-width: 430px;
  padding: 8px;
  min-height: 454px;
  gap: 10px;
  max-height: 100%;
  overflow-y: scroll;
}

.successModalContent {
  min-height: auto;
}

.successTitle {
  margin-bottom: 20px;
}

.topButton {
  height: 32px;
  border: 1px var(--white) solid;
  border-radius: 10px;
  font-size: 14px;
  line-height: 16px;
  color: var(--white);
  font-weight: 400;
  padding: 8px 16px;
  margin: 24px 10px 0 auto;
}

.downButton {
  color: var(--white);
  font-weight: 400;
  border-radius: 24px;
  background-color: var(--dark-blue);
  padding: 20px;
  margin: auto 12px 14px;
  font-size: 14px;
  line-height: 16px;
}

.caseBlock {
  display: flex;
  flex-direction: column;
  font-size: 18px;
  line-height: 135%;
}

.questionBlock {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: flex-end;
  font-size: 18px;
  line-height: 135%;
  margin-left: 18px;
}

.title {
  font-size: 20px;
  margin-left: 18px;
  margin-bottom: 10px;
  font-weight: 500;
}

.textarea {
  outline: none;
  background: transparent;
  min-height: 148px;
  border-radius: 24px;
  border: 1px #CFDCE8 solid;
  padding: 20px;
  color: var(--white);
  font-family: inherit;
  font-size: 16px;
  line-height: 18px;
  transition: border 300ms;
  resize: none;
  margin-bottom: 14px;
  width: 100%;
}

.stillInProgressModal {
  border-radius: 20px 20px 0 0;
  height: 90%;
  top: auto;
  left: 0;
  right: 0;
  bottom: 0;
  transform: none;
}

.confirmButton {
  color: var(--white);
  font-weight: 400;
  border-radius: 24px;
  background-color: var(--dark-blue);
  min-height: 56px;
  margin: auto 12px 14px;
  font-size: 14px;
  line-height: 16px;
}

.iconWrap {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100px;
  height: 100px;
  margin-bottom: 20px;
  border: 2px solid;
  border-radius: var(--border-round);
  stroke: var(--black);
  background: var(--white);
  border-color: var(--white);
}

.inputArea {
  position: relative;
}

.audioInput {
  position: absolute;
  top: 7px;
  right: 7px;
}

.summary {
  text-align: left;
  padding: 0 8px;
}

.postponedTo {
  text-align: center;
  padding: 0 8px;
}

.successChild {
  display: flex;
  flex-direction: column;
  gap: 10px;
}
