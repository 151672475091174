@import "src/app/styles/vars";

.navTabsList {
  --nav-border-radius: 4px;
  --nav-bg: var(--gray1);
  --nav-spacing: 22px;

  display: flex;
  flex-wrap: wrap;
  flex: 0 0 auto;
  padding-left: 0;
  margin-bottom: 0;
  list-style: none;
  background-color: var(--nav-bg);
  border-radius: var(--nav-border-radius);
  overflow: hidden;

  margin-bottom: var(--nav-spacing);
}

.navTabsListItem {
  --item-padding-y: 7px;
  --item-padding-x: 8px;
  --item-font-size: 15px;
  --item-line-height: 1.2;
  --item-font-weight: 400;
  --item-color: var(--white);
  --item-active-color: var(--gray);
  --item-active-bg: var(--white);

  flex: 1 1 auto;
  text-align: center;

  display: block;
  padding: var(--item-padding-y) var(--item-padding-x);

  background: 0 0;
  border-radius: 4px;

  cursor: pointer;

  transition: color .15s ease-in-out, background-color .15s ease-in-out, border-color .15s ease-in-out;

  outline: none;
  border: none;
  appearance: none;

  font-size: var(--item-font-size);
  font-weight: var(--item-font-weight);
  line-height: var(--item-line-height);
  color: var(--item-color);
  text-decoration: none;

  &.active {
      color: var(--item-active-color);
      background-color: var(--item-active-bg);
  }
}

.navTabsPanels {
  height: 100%;
  overflow: hidden;
}

.navTabsPanel {
  height: 100%;
  display: flex;
  flex-direction: column;
  overflow: auto;
  outline: none;
}

.customerLanguageWrap {
  display: flex;
  flex-direction: column;
  width: 100%;
  background: var(--bg-black);
  border-radius: var(--border-radius);
  position: relative;
}

.customerLanguageSelect {
  $padding: 15px;

  display: flex;
  align-items: center;
  justify-content: space-between;

  padding: $padding;

  background: transparent;
  border: none;
  outline: none;
  cursor: pointer;

  text-align: start;
  font-weight: 600;
  font-size: 20px;
  line-height: 1.125;
  letter-spacing: 0.02em;
  color: var(--white);
}

.customerLanguageTitle {
  text-transform: capitalize;
}

.customerLanguageLabel {
  margin-left: auto;
  margin-right: .5em;
}

.customerLanguageIcon {
  $angle: 90deg;
  display: flex;
  flex-shrink: 0;
  rotate: $angle;
  transition: 300ms;

  &.open {
    rotate: -$angle;
  }
}

.customerLanguageOptions {
  position: absolute;
  top: 51px;
  right: 0;
  min-width: 160px;
  background: var(--gray1);
  border-radius: 5px;
  cursor: pointer;
  overflow: hidden;
  outline: none;
  z-index: 1;
}

.customerLanguageOption {
  position: relative;
  padding: 10px;
  text-align: center;
  color: var(--light-blue);
  text-transform: uppercase;

  &:hover {
    background: var(--blue);
  }

  &:not(:last-child) {
    border-bottom: 1px solid var(--gray2);
  }
}

.reusableToggle {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  padding: 15px;
  background: var(--bg-black);
  border-radius: var(--border-radius)
}

.invitationOptions {
  display: flex;
  flex-direction: column;
  gap: 4px;
}
