.content {
  padding-bottom: 96px;
}

.table {
  & > :nth-child(6n+4), & > :nth-child(6n+5), & > :nth-child(6n+6) {
    background: var(--gray2);
  }

  & > :nth-child(3n) {
    border-bottom: 1px solid var(--gray3);
  }

  & > :last-child {
    border-bottom: none;
    border-radius: 0 0 8px 8px;
  }
}
