.title {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.icons {
  display: flex;
  gap: 10px;
}

.icon {
  cursor: pointer;
}
