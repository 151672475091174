.invitation {
  display: flex;
  justify-content: space-between;
  gap: 10px;
  padding: 10px 10px 8px;
  min-height: 77px;
  background: #121212;
  border-radius: 6px;
  position: relative;
}

.invitationCode {
  font-weight: 500;
  font-size: 16px;
  line-height: 130%;
  letter-spacing: 0.02em;
  color: var(--white);
  margin-bottom: 3px;
}

.invitationCreatedAt {
  font-weight: 300;
  font-size: 12px;
  line-height: 120%;
  letter-spacing: -0.03em;
  color: #D9D9D9;
  margin-bottom: 7px;
}

.invitationRecommendation {
  font-weight: 300;
  font-size: 12px;
  line-height: 120%;
  letter-spacing: -0.03em;
  color: #5277FD;
}

.invitationStatus {
  text-align: right;
  margin-left: auto;
}

.invitationStatusLabel {
  font-weight: 400;
  font-size: 12px;
  line-height: 120%;
  letter-spacing: -0.03em;
  color: #D9D9D9;
  margin-top: 3px;
}

.invitationStatusValue {
  font-weight: 300;
  font-size: 12px;
  line-height: 120%;
  letter-spacing: -0.03em;
  margin-top: 7px;

  &.default,
  &.accepted {
    color: var(--green);
  }

  &.expired {
    color: var(--red);
  }
}

.invitationUsername {
  margin-top: 7px;
  margin-bottom: 8px;
}

.notification {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background-color: rgba(black, 80%);
  
  display: flex;
  align-items: center;
  justify-content: center;
}