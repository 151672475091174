.slider {
  display: flex;
  border-bottom: 2px solid rgba(255, 255, 255, 0.30)
}

.slide {
  position: relative;
  padding: 16px 8px;
  margin-bottom: -2px;
  border: none;
  color: rgba(255, 255, 255, 0.50);
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 16px;

  &::after {
    position: absolute;
    bottom: 0;
    left: 0;
    right: 0;
    content: '';
    height: 2px;
    background-color: transparent;
  }

  &[data-selected=true] {
    border-color: white;
    color: white;

    &::after {
      background-color: white;
    }
  }
}
