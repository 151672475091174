.button {
  font-size: 14px;
  line-height: 16px;
  margin-top: 12px;
  padding: 20px;
  border: none;
}

.setupButton {
  background-color: var(--dark-blue);
}

.wrapper {
  position: relative;
  z-index: 150;
}
