.socials {
  display: flex;
  align-items: center;
  justify-content: center;
  column-gap: 20px;
  width: 100%;
  color: var(--white);
}

.icon {
  color: var(--white);
}
