.gap {
  margin-top: 20px;
}

.content {
  padding-left: 4px;
  padding-right: 4px;
}

.title {
  display: flex;
  align-items: baseline;
  justify-content: space-between;
  padding: 24px 4px;
  font-size: 20px;
  font-weight: 500;
  letter-spacing: -0.24px;
}

.link {
  color: var(--white-op60);
  font-size: 14px;
  font-weight: 400;
  line-height: 16px;
  padding-left: 10px;
  margin-left: auto;
  text-align: right;
}
