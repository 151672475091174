$swap-width: 32px;

.exchanger {
  position: relative;
  display: flex;
  flex-direction: column;

  &:has(.swap)::after {
    display: block;
  }
}

.exchanger::after {
  position: absolute;
  content: '';
  top: 50%;
  left: 0;
  display: none;
  width: calc(100% - $swap-width);
  height: 1px;
  translate: 0 -50%;
  background: var(--blue-op20);
}

.swap {
  color: var(--light-blue);
}

.btn {
  padding: 12px 16px;
  font-size: 14px;
  border-radius: 12px;
  border: 1px solid var(--dark-blue);
  color: var(--white);
}

$inputWrapWidth: 100%;

.inputWrap {
  position: relative;
  width: 100%;
}

// небольшой хак для неправильного порядка стилей
.input.input {
  width: calc($inputWrapWidth - 20%);
  padding: 12px 0;
  border: 0;
  font-size: 34px;
  color: var(--white);
}

.clearIconWrap,
.swap {
  cursor: pointer;
  border-radius: var(--border-round);
}

.clearIconWrap {
  position: absolute;
  top: 50%;
  right: 20px;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 20px;
  height: 20px;
  translate: 0 -50%;
  background: var(--gray3);
}

.clearIcon {
  stroke: var(--black);
}

.swap {
  margin: 10px 0;
  align-self: flex-end;
  width: $swap-width;
  height: $swap-width;
  padding: initial;
  border: 0;
  background: var(--blue-op20);

  &:disabled {
    background: var(--white-op5);
    color: var(--gray);
  }
}

.crossExchange {
  margin-top: 12px;
}

.background {
  position: fixed;
  top: 0;
  left: 0;
  z-index: 101;
  width: 100vw;
  height: 100vh;
  background: var(--black-op40);
  backdrop-filter: blur(2px);
}
