.modal {
  position: fixed;
  top: 0;
  left: 0;
  z-index: 9980000;
  width: 100%;
  height: 100%;
  background: var(--black-op40);
  backdrop-filter: blur(2px);
}

.content {
  position: fixed;
  z-index: 999;
  top: 50%;
  left: 50%;
  display: flex;
  flex-direction: column;
  row-gap: 20px;
  max-width: 360px;
  width: 100%;
  padding: 20px;
  border-radius: var(--border-radius);
  transform: translate(-50%, -50%);
  background: var(--bg-black);
}

.webApp {
  top: 33%
}
