.box {
  background: #222222;
  border-radius: 28px;
  padding: 16px;
  margin-top: 2px;
  margin-bottom: 2px;
}

.title {
  font-size: 14px;
  line-height: 16px;
  color: var(--white);
  opacity: 0.5;
}

.inputWrap {
  position: relative;
  width: 100%;
  padding: 4px 0 0;
}

.input {
  padding: 0;
  border: 0;
  font-size: 18px;
  color: var(--white);
  width: 100%;
  background: transparent;
  height: auto;
  outline: none;
  font-family: inherit;
  resize: none;
}

.titleContainer {
  display: flex;
  justify-content: space-between;
}
