.message {
  position: relative;
  background-color: #434343;
  border-radius: 20px;
  padding: 12px 60px 12px 12px;
  //flex-basis: 50%;
  max-width: 80%;
}

.me {
  margin-left: auto;
  background-color: var(--blue);;
}

.imageWrapper {
  min-width: 80%;
  flex-basis: 50%;
}

.text {
  unicode-bidi: plaintext;
  text-align: start;
  white-space: pre-line;
  word-break: break-word;
}

.name {
  font-weight: 700;
  margin-bottom: 10px;
}

.time {
  position: absolute;
  bottom: 11px;
  right: 12px;

  overflow-wrap: break-word;
  word-break: break-word;
  color: #9d9da5;
  flex-shrink: 0;
  max-width: 50px;
  margin-left: 12px;
}

.image {
  display: block;
  max-width: 100%;
  border-radius: 15px;
}

.video {
  display: block;
  max-width: 100%;
  border-radius: 15px;
}

.iconDocument {
  width: 50px;
  height: 50px;
}

.doc {
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 5px;
}

.docMessage {
  display: flex;
  flex-direction: column;
  gap: 5px;
}

.docLink {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 5px;
}

.docCaption {
  white-space: normal;
  word-wrap: break-word;
}

.docLinkText {
  text-decoration: underline;
}

.docLinkSize {
  margin-top: 4px;
  font-size: 12px;
}

.audioPlayer {
  width: 100%;
}

.cntComment {
  border-radius: 0;
  color: var(--black);
  padding: 30px;
  display: flex;
  align-items: center;
  justify-content: center;
  box-shadow: 3px 3px 10px rgba(0, 0, 0, 0.2);
  clip-path: polygon(25px 0, 100% 0, 100% 100%, 0 100%, 0 25px);
  background: #f1cf4b;
  line-height: 20px;
}

.cntComment::after {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  border-top: 26px solid rgba(255, 255, 255, 0);
  border-right: 26px solid #e7c440;
  border-bottom: 1px solid rgba(0,0,0, 0.5);
}

.concave {
  clip-path: url(#concaveLeft);
}

.blackTime {
  color: #606060;
}

.audioWrapper {
  display: flex;
  flex-direction: column;
  gap: 10px;
  width: 100%;
}

.audioPlayerWrapper {
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 10px;
  width: 100%;
}

.transcriptionIconContainer {
  border: 1px solid var(--light-blue);
  border-radius: 6px;
  padding: 3px;
  color: var(--light-blue);
  width: 25px;
  height: 25px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.hide {
  display: none;
}
