.row {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  position: relative;

  &:first-child {
    font-weight: 600;
  }

  &:nth-child(2n) {
    background: var(--gray1);
  }
}
