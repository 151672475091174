.content {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  padding: 16px 3px 16px 6px;
  align-items: center;
}

.button {
  min-width: 113px;
  height: 32px;
  border: 1px var(--white) solid;
  border-radius: 10px;
  font-size: 14px;
  line-height: 16px;
  color: var(--white);
  font-weight: 400;
  padding: 8px 16px;
}

.icon {
  path[id="logo-icon-stars"] {
    animation: op 2.5s ease-in infinite;
  }
}


@keyframes op {
  0% {
    opacity: 100%;
  }
  50% {
    opacity: 0%;
  }
  100% {
    opacity: 100%;
  }
}
