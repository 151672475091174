.root {
  display: flex;
  background-color: var(--blue);
  padding: 10px 15px;
  font-size: 18px;
  border-radius: var(--border-radius);
  align-items: center;
  justify-content: center;
  font-weight: 700;
}

.disable {
  opacity: 0.5;
}

.icon {
  margin-right: 6px;
}
