.slider {
  -ms-overflow-style: none; /* IE and Edge */
  scrollbar-width: none; /* Firefox */

  &::-webkit-scrollbar {
    display: none;
  }
}

.slide {
  border: 3px solid transparent;
  cursor: pointer;

  &[data-selected='true'] {
    border-color: var(--blue);
  }
}
