.wrap {
  background: #222222;
  border-radius: 28px;
  padding: 16px;
  margin-top: 2px;
  margin-bottom: 2px;
}

.label {
  display: flex;
  flex-direction: column;
  margin-bottom: 2px;
}

.labelTitle {
  font-size: 14px;
  line-height: 16px;
  color: var(--white);
  opacity: 0.5;
}

.inputWrap {
  position: relative;
  width: 100%;
}

// небольшой хак для неправильного порядка стилей
.input.input {
  padding: 12px 0;
  border: 0;
  font-size: 18px;
  color: var(--white);
}
