.aiPrioritized {
  display: flex;
}

.aiPrioritizedIcon {
  margin: 3px 10px 3px 0;
}

.aiPrioritizedTitle {
  font-weight: 400;
  font-size: 10px;
  line-height: 14px;
  color: #6D6D6D;
}

.aiPrioritizedDescription {
  font-weight: 600;
  line-height: 135%;
  font-size: 14px;
  color: var(--black);
}

.darkTheme {
  color: var(--white);
}
