@import 'src/app/styles/vars';

$small-gap: 4px;
$color-gray: #c9c9c9;

%default-font-styles {
  font-size: 12px;
  line-height: 1;
}

.details {
  display: flex;
  column-gap: $small-gap;
  width: 100%;
}

.info {
  display: flex;
  flex-direction: column;
  justify-content: center;
  row-gap: $small-gap;
}

.number {
  @include text-overflow;
  @extend %default-font-styles;
}

.user {
  @include text-overflow;
  @extend %default-font-styles;
}

.number,
.user {
  overflow: hidden;
  text-overflow: ellipsis;
}

.provider {
  @include text-overflow;
  @extend %default-font-styles;
  color: $color-gray;
}

.exchangeMethod {
  display: flex;
  column-gap: $small-gap;
  font-size: 10px;
  line-height: 1;
  font-weight: 300;
}

.success {
  color: var(--green);
}

.cancelled {
  color: var(--red);
}

.status {
  @extend %default-font-styles;
}

.paymentTitle {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  gap: 5px;
}

.title {
  display: flex;
  flex-direction: column;
}

.description {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: baseline;
  gap: 6px;
}

.arrow {
  font-size: 12px;
}
