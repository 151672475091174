.skeleton {
  border-radius: 12px;
}

.balance {
  margin-bottom: 20px;
}

.balanceTitle {
  font-size: 20px;
  font-weight: 600;
}

.list {
  display: flex;
  flex-direction: column;
  row-gap: 5px;
}
