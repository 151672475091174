.form {
  display: flex;
  flex-direction: column;
}

.values {
  display: flex;
  flex-direction: column;
  row-gap: 12px;
  color: var(--white);
}

.error {
  color: var(--red);
  margin-top: 1em;
}

.buttons {
  margin-top: 24px;
  display: flex;
  flex-direction: column;
}

.button {
  padding: 6px;
  cursor: pointer;
}

.submit {
  background: var(--dark-blue);
  color: var(--white);
  border: 0;
  border-radius: 24px;
  font-size: 14px;
  line-height: 16px;
  font-weight: 400;
  transition: 300ms;
  padding: 20px 40px 20px 40px;

  &:disabled {
    color: var(--white-op30);
  }
}

.close {
  width: 28px;
  height: 28px;
  position: absolute;
  top: 16px;
  right: 16px;
  background: transparent;
  border: 0;
  margin: 8px 8px 0 0;
}

.closeIcon {
  width: 10px;
  height: 10px;
}

.checkbox {
  gap: 16px;
  padding: 0 16px;
  margin-top: 12px;
}

.checkboxContent {
  color: var(--white-op30);
  font-size: 14px;
  font-weight: 400;
  line-height: 16px;
}

.checkboxContent > a {
  text-decoration: underline;
  cursor: pointer;
}
