.button {
  background-color: #111;
  color: #92A3B8;

  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 18px;

  padding: 11px;
  border-radius: 24px;
}
