@import "src/app/styles/vars";

$gap: 8px;
$height: 55px;

.form {
    position: sticky;
    right: 0;
    bottom: 0;
    left: 0;
    display: flex;
    flex-direction: column;
    gap: $gap;
    margin-top: auto;
    padding-bottom: 16px;
    background: var(--black);
  }
  
  .controls {
    display: flex;
    align-items: center;
    gap: $gap;
  }

  .input {
    flex: 2;
    height: $height;
    border: 1px solid var(--green);
    border-radius: var(--border-radius);
  }

  .inputField {
    background-color: transparent;
    font-size: 40px;
    letter-spacing: 0.02em;
    color: var(--green);
    padding: 0;

    &.active {
      background-color: var(--green);
      color: var(--black);
    }
  }

  .copy {
    flex: 1;
    height: $height;
  }

  .actions {
    display: flex;
    align-items: center;
    gap: $gap;
  }

  .button {
    @include text-overflow;
    flex: 8;
    text-overflow: ellipsis;
  }

  .qr {
    flex: 1;
    display: flex;
    justify-content: center;
    align-items: center;
  }