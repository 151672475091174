.success {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  text-align: center;
}

.success[data-balance-type='send'] {
  .iconWrap {
    background: var(--white);
    border-color: var(--white);
  }

  .title {
    color: var(--white);
  }
}

.success[data-balance-type='receive'] {
  .iconWrap {
    background: var(--green);
    border-color: var(--green);
  }

  .title {
    color: var(--green);
  }
}

.iconWrap {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100px;
  height: 100px;
  margin-bottom: 20px;
  border: 2px solid;
  border-radius: var(--border-round);
  stroke: var(--black);
}

.title {
  display: flex;
  flex-direction: column;
  row-gap: 4px;
  width: 100%;
  font-size: 26px;

  &:after {
    content: '';
    border-bottom: 1px solid var(--gray2);
    padding-top: 30px;
    margin-bottom: 30px;
  }
}

.comment {
  word-wrap: break-word;
  max-width: 250px;
  margin-bottom: 8px;
  font-size: 20px;
  line-height: 22px;
  color: var(--white);
}

.amount {
  word-wrap: break-word;
  max-width: 250px;
}

.date {
  color: var(--gray3);
  font-size: 15px;
  line-height: 10px;
}
