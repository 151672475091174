.selectBtn {
  padding: 16px 40px;
  text-align: center;
}

.selectOptions {
  text-align: center;
}

.wa {
  background-color: var(--whatsapp);
}

.cm {
  background-color: var(--dark-blue);
}
