.label {
  display: flex;
  flex-direction: column;
  color: var(--white);
}

.input {
  width: 100%;
  display: flex;
  outline: none;
  color: inherit;
  padding: 16px 20px;
  border: 1px solid;
  border-radius: var(--border-radius);
  transition: var(--duration);

  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 130%;
  letter-spacing: 0.02em;

  &:placeholder-shown {
    color: var(--white);
  }

  &:hover,
  &:focus {
    border-color: var(--white-op60);
  }

  &:disabled {
    cursor: default;
    border-color: transparent;
  }

  &:read-only {
    cursor: pointer;
    border-color: transparent;
  }
}

.outlined {
  background-color: transparent;
  border-color: var(--white-op20);
}

.filled {
  text-align: center;
  background: var(--white-op20);
  border-color: var(--white-op20);
}

.valid {
  border-color: var(--green);
  color: var(--green);
}

.invalid {
  border-color: var(--red);
  color: var(--red);
}

.error {
  color: var(--red);
}

.errorMessage {
  padding-top: 10px;
  text-align: center;
}

.disabled {
  cursor: default !important;

  &:hover,
  &:focus {
    border-color: inherit;
  }
}
