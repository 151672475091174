.button {
  color: var(--white);
  padding: 8px 16px;
  font-weight: 400;
  font-size: 16px;
  line-height: 135%;
}

.topButton {
  border: 1px var(--white) solid;
  border-radius: 12px;
  height: 32px;
  font-size: 14px;
  line-height: 16px;
}

.bottomButton {
  background: #1A1A1A;
  border-radius: 28px;
  height: 54px;
  justify-content: left;
}

.blue {
  background: var(--dark-blue);
}

.header {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  padding-bottom: 13px;
  padding-top: 13px;
}

.buttons {
  display: flex;
  flex-direction: column;
  gap: 4px;
  margin: auto 0 0;
  padding-top: 4px
}

.buttonIcon {
  width: 20px;
  height: 20px;
  margin: auto 10px auto 4px;
}

.buttonChild {
  display: flex;
  align-items: center;
  justify-content: center;
}

.deleteButton {
  color: var(--red);
}

.historyButton {
  border: 1px var(--white) solid;
  border-radius: 12px;
  padding: 7px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.historyIcon {
  height: 20px;
  width: 20px;
}

.takeOnButtonIcon {
  background-color: var(--white);
  border-radius: 50%;
  padding: 2px;
}
