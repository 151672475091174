.widget {
  display: flex;
  flex-direction: column;
  height: 100%;
  row-gap: 16px;
}

.addButton {
  // position: sticky;
  // right: 0;
  // bottom: 0;
  // left: 0;
  padding-bottom: 16px;
  margin-top: auto;
  background: var(--black);
  border-radius: 8px 8px 0 0;

  button {
    padding-left: 10px;
    padding-right: 10px;
  }
}
