.wrap {
  display: flex;
  flex-direction: column;
}

.wrap,
.info {
  row-gap: 10px;
}

.header {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.value {
  &.plus {
    color: var(--green);
  }

  &.minus {
    color: var(--red);
  }
}

.info {
  display: flex;
  flex-direction: column;
}

.balanceInfo {
  display: flex;
  align-items: center;
}

.avatar {
  margin-right: 5px;
}

.transactionBucket {
  max-width: 170px;
  word-wrap: break-word;
  color: inherit;
}

.transactionComment {
  max-width: 300px;
  word-wrap: break-word;
}

.cancelButton {
  width: 50%;
  height: 35px;
  margin: auto;
  //padding: 5px;
  //border-radius: 50%;
  //font-size: 10px;
  //width: 38px;
  //height: 38px;
  //border: var(--white) 2px solid;
  //display: flex;
  //justify-content: center;
  //align-items: center;
}

.canceled {
  color: var(--gray);
  text-decoration: line-through;
}

.canceledHeader {
  color: var(--red-new);
  text-align: center;
}
