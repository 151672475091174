.card {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.btns {
  display: flex;
  column-gap: 8px;
}

.btn {
  padding: 5px 10px;
  font-size: 10px;
  line-height: 12px;
  font-weight: 600;
}

.green {
  color: var(--green);
}

.red {
  color: var(--red);
}

.obligationsTransferBtn {
  width: 22px;
  height: 22px;
  border-radius: 50%;
}

.obligationsTransferIcon {
  width: 14px;
  height: 13px;
}

.amount {
  word-wrap: break-word;
  max-width: 40%;
}
