@import 'src/app/styles/vars';

.content {
  @include content-container();

  display: flex;
  flex-direction: column;
  height: 100%;
  padding-top: 20px;
  padding-bottom: 20px;
  margin-bottom: var(--navigation-menu-height);
  overflow: auto;
}

.unsetBP {
  padding-bottom: initial;
}

.unsetBM {
  margin-bottom: initial;
}

.unsetTP {
  padding-top: initial;
}

.unsetLRP {
  padding-left: 0;
  padding-right: 0;
}
