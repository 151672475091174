.container {
  display: flex;
  flex-flow: column nowrap;
}
.table {
  display: grid;
  border-radius: var(--border-radius);
  background: var(--gray2);
  overflow: hidden;
}

.header {
  padding: 1rem;

  &:first-child {
    text-align: right;
  }

  &:last-child {
    text-align: left;
  }
}

.row {
  display: grid;
  grid-template-columns: repeat(2, 1fr);

  &:first-child {
    font-weight: 600;
  }

  &:nth-child(2n) {
    background: var(--gray1);
  }

  .feeRate {
    &:first-child {
      border-right: 1px solid var(--gray3);
    }
  }
}

.caption {
  text-align: center;
  margin: 1rem;
  color: var(--gray3);
  font-weight: 400;
  font-size: 13px;
  line-height: 130%;
  letter-spacing: -0.03em;
  font-style: normal;
}
