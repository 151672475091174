.empty {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
}

.assistants {
  display: flex;
  flex-direction: column;
  gap: 10px;
  margin-bottom: 10px;
}

.loading {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 16px;
  padding: 24px 0;
}

.header {
  margin-bottom: 16px;
  font-size: 18px;
  font-weight: 600;
  color: var(--white);
}

.headerContainer {
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  margin-bottom: 16px;
  flex-wrap: wrap;
  gap: 12px;
}

.headerContainer .header {
  margin-bottom: 0;
}

.wrap {
  margin-bottom: 32px;
}

.search {
  margin-bottom: 16px;
  width: 100%;
  padding: 0;
}

.ownerSection {
  margin-bottom: 20px;
  display: flex;
  justify-content: flex-end;
}

.uploadSection {
  margin-top: 20px;
  background: linear-gradient(to bottom, rgba(40, 40, 40, 0.7), rgba(25, 25, 25, 0.7));
  border-radius: 12px;
  padding: 18px;
  border: 1px solid var(--gray3);
  width: 100%;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.2);
}

.compactUploadContainer {
  display: flex;
  flex-direction: column;
  width: 100%;
}

.compactInfo {
  display: flex;
  flex-direction: column;
  gap: 12px;
}

.compactDescription {
  font-size: 14px;
  line-height: 1.4;
  color: var(--gray5);
  padding-left: 2px;
  display: block;
  margin-bottom: 4px;
}

.compactButtonsContainer {
  display: flex;
  gap: 10px;
  flex-wrap: wrap;
}

.compactButton {
  font-size: 14px;
  padding: 8px 14px;
  height: 36px;
  background-color: var(--gray2);
  color: var(--white);
  border: 1px solid var(--gray3);
  border-radius: 8px;
  min-width: 130px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  transition: background-color 0.2s, border-color 0.2s;
}

.compactButton:hover {
  background-color: var(--gray3);
  border-color: var(--gray4);
}

.compactUploadButton {
  font-size: 14px;
  padding: 8px 14px;
  height: 36px;
  background-color: var(--blue);
  color: var(--white);
  border: none;
  border-radius: 8px;
  transition: background-color 0.2s;
}

.compactUploadButton:hover {
  background-color: var(--blue-hover);
} 