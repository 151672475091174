.contacts {
  display: flex;
  flex-direction: column;
  row-gap: 8px;
  margin-bottom: 8px;

  &:has([data-empty='true']) {
    height: 100%;
  }
}
