.group {
  cursor: pointer;
  background-color: #32303099;
  display: flex;
  align-items: center;
  white-space: nowrap;
  font-weight: 500;
  padding: 16px 12px;
  border-bottom: 1px solid var(--gray);

  &:first-child {
    border-top-right-radius: var(--border-radius);
    border-top-left-radius: var(--border-radius);
  }

  &:last-child {
    border-bottom: none;
    border-bottom-right-radius: var(--border-radius);
    border-bottom-left-radius: var(--border-radius);
  }
}

.red {
  color: var(--red);
}

.icon {
  max-width: 25px;
  width: 100%;
  margin-right: 20px;
}

.button {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-left: auto;
  border: none;
  background: none;
  fill: none;
}

.link {
  color: inherit;
  text-decoration: none;
}
