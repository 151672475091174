.root {
  display: flex;
  justify-content: space-between;
  flex-direction: column;
  width: 100%;

  .rate {
    margin-right: 0;
    border-radius: 12px;
    border: 1px solid var(--white-op30);
  }

  .text {
    font-size: 16px;
    color: var(--white);
  }
}

.slider {
  display: flex;
  column-gap: 5px;
  overflow: auto;
  width: 100%;
  padding-bottom: 8px;
}

.slide {
  flex-grow: 1;
  text-align: center;
  min-width: 90px;
  border: none;
  font-size: 14px;

  div {
    min-height: 50px;
    padding: 16px;
    border-radius: 24px;
  }
}

.enumSlide {
  border: 2px solid var(--dark-blue);
}

.colorSlide {
  border: 4px solid black;
}

.slide[data-selected=true] {
  border: none;
  color: white;
  transition: all 100ms ease;

  div {
    background: var(--dark-blue);
    border-color: transparent;
  }

  .colorSlide {
    border-width: 2px;
    box-shadow: inset 0px 0px 0px 2px #000;
    border-color: var(--dark-blue)
  }
}

.paramName {
  font-size: 14px;
  line-height: 16px;
  margin: 0 auto 8px 14px;
}

// .item {
//   max-width: 74px;
//   width: 100%;
// }

// .btn {
//   padding: 5px 10px;
//   font-size: 10px;
//   line-height: 25px;
// }
