.slider {
  display: flex;
  padding: 16px 0 8px;
  overflow: auto;
}

.slide {
  position: relative;
  width: fit-content;
  border-radius: 28px;

  &::after {
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    width: 100%;
    height: 100%;
    content: '';
    background-color: rgba(0, 0, 0, 0.5);
  }

  &[data-selected='true'] {
    border-color: transparent !important;

    &::after {
      background-color: transparent;
    }
  }
}
