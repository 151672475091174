:root {
  --white: #ffffff;
  --blue: #4067f5;
  --light-blue: #bac9ff;
  --green: #62d488;
  --black: #000000;
  --bg-black: #181818;
  --gray: #646465;
  --gray1: #333333;
  --gray2: #4f4f4f;
  --gray3: #828282;
  --red: #e4513d;
  --dark-blue: #1b446a;
  --green-new: #008000;
  --red-new: #cd1c18;
  --yellow-new: #ffed29;

  --duration: 0.3s;

  --white-op60: rgba(255, 255, 255, 0.6);
  --white-op30: rgba(255, 255, 255, 0.3);
  --white-op20: rgb(55, 55, 55);
  --white-op5: rgba(255, 255, 255, 0.05);

  --gray-op40: rgba(100, 100, 101, 0.4);

  --black-op40: rgba(0, 0, 0, 0.4);

  --blue-op20: rgba(41, 114, 255, 0.2);

  --green-op20: rgba(14, 191, 128, 0.2);

  --yellow-op50: rgba(255, 255, 0, 0.5);

  --border-radius: 8px;
  --border-round: 50%;

  --doc-height: 100%;

  --layout-margin: 8px;
  --touch-max-width: 720px;

  --navigation-menu-height: 88px;

  --bottom-panel-height: 109px;

  --telegram: #1c93e3;
  --whatsapp: #2bc546;
}

@mixin content-container {
  width: 100%;
  padding: 0 var(--layout-margin);
  margin: 0 auto;

  @media (min-width: 768px) {
    max-width: var(--touch-max-width);
  }
}

@mixin text-overflow {
  overflow: hidden;
  text-overflow: clip;
  white-space: nowrap;
}

@mixin scrollbar {
  &::-webkit-scrollbar {
    width: 8px;
    height: 8px;
    background: var(--gray2);
    border-radius: var(--border-radius);
  }

  &::-webkit-scrollbar-thumb {
    background: var(--gray3);
    border-radius: var(--border-radius);
  }
}

@mixin tabsStyles {
  .tabs {
    display: flex;
    width: 100%;
    background: var(--gray1);
    border-radius: var(--border-radius);
  }

  .tab {
    display: flex;
    flex: 1;
    align-items: center;
    justify-content: center;
    padding: 6px;
    font-size: 15px;
    outline: none;
    background: transparent;
    color: var(--white);
    border: 1px solid transparent;
    border-radius: var(--border-radius);
    cursor: pointer;
  }

  .tab.selected {
    background: var(--white);
    border-color: var(--white);
    color: var(--black);
  }
}
