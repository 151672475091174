.container {
  display: flex;
  flex-direction: column;
  height: 100%;
}

.box {
  margin-bottom: 32px;
}

.title {
  font-weight: 600;
  font-size: 32px;
  line-height: 130%;
  letter-spacing: 0.02em;
  margin-bottom: 8px;
}

.reception {
  padding: 10px;
  width: 100%;
  min-height: 100px;
  resize: vertical;
  background: transparent;
  outline: none;
  font-family: inherit;
  font-size: inherit;
  color: var(--white);
  border-radius: var(--border-radius);
}

.moneyReadyWrap {
  margin-bottom: auto;
}

.moneyReady {
  margin-bottom: 32px;
  letter-spacing: 0.02em;
  color: var(--white-op60);
}

.actions {
  position: sticky;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 10;
  display: flex;
  flex-direction: column;
  row-gap: 8px;
  border-radius: 8px 8px 0 0;
  margin-top: auto;
}

.error {
  display: flex;
  align-items: center;
  justify-content: center;
  padding-top: 16px;
  margin-bottom: 20px;
  text-align: center;
  color: var(--red);
}

.timerTab {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  height: 72px;
  padding: 16px;
  text-align: left;
  position: relative;
  background: #222222;
  border-radius: 28px;
  margin-bottom: 32px;
  margin-top: 2px;
}

.timer {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  width: 68px;
  height: 40px;
  border: 1px solid rgba(255, 255, 255, .5);
  border-radius: 12px;
  font-weight: 400;
  font-size: 14px;
  line-height: 16px;
}

.timerText {
  height: 16px;
  font-size: 14px;
  line-height: 16px;
  font-weight: 400;
  opacity: .5;
}

.spinner {
  margin-left: auto;
  margin-right: auto;
}
