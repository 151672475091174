.currencyInfo {
  background: #222222;
  border-radius: 28px;
  padding: 16px;
  margin-top: 2px;
  margin-bottom: 2px;
}

.currencyLabel {
  display: flex;
  flex-direction: column;
  margin-bottom: 2px;
}

.currencyLabelTitle {
  font-size: 14px;
  line-height: 16px;
  color: var(--white);
  opacity: 0.5;
}

.currencyAmount {
  font-weight: 400;
  font-size: 40px;
  line-height: 48px;
  color: var(--white);
  margin-bottom: 2px;
  margin-top: 2px;
}

.currencyPaymentMethod {
  font-weight: 400;
  font-size: 14px;
  line-height: 16px;
  color: var(--white);
  opacity: 0.5;
  margin-top: 2px;
}

.skuInfo {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  width: 100%;
  margin-bottom: 2px;
}

.skuCard {
  width: 100%;
  height: 160px;
  border-radius: 28px;
  position: relative;
  overflow: hidden;
  background: #1B1B1B center;
  background-size: cover;
}

.skuInfoTitle {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;

  &:after {
    content: '';
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    background-color: var(--black);
    z-index: 5;
    opacity: 0.4;
    box-shadow: inset 0 40px 45px 25px black;
  }

  > * {
    position: relative;
    z-index: 10;
  }
}

.skuName {
  font-weight: 400;
  font-size: 14px;
  line-height: 16px;
  color: var(--white);
  margin: 16px 16px auto 16px;
}

.skuParamTab {
  display: block;
  background: #222222;
  min-width: calc(100% / 3 - 4px);
  height: 72px;
  margin: 4px 2px 0 2px;
  border: #50a56c 1px;
  border-radius: 24px;
  padding: 16px 15px;
}

.skuParamName {
  font-size: 12px;
  color: #ffffff;
  opacity: 50%;
  line-height: 16px;
}

.skuParamValue {
  font-size: 16px;
  line-height: 24px;
}
