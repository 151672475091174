.slide {
  position: relative;
  padding: 11px;
  width: 140px;
  height: 150px;
  background-size: cover;
  background-position: center;
  border-radius: 25px;
}

.title {
  font-size: 14px;
  line-height: 16px;
  color: var(--black);
  background-color: rgba(255, 255, 255, 0.5);
  padding: 5px;
  border-radius: 5px;
}
