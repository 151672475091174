.avatar {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  font-weight: 600;
  font-size: 26px;
  background-color: var(--white-op20);
  border-radius: var(--border-round);
  color: var(--white);

  &.withDotIndicator {
    position: relative;
  }
}

.dotIndicator {
  position: absolute;
  top: 0;
  right: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 16px;
  height: 16px;
  font-weight: 500;
  font-size: 10px;
  line-height: 20px;
  border-radius: var(--border-round);
  color: var(--black);
}

.img {
  width: 100%;
  height: 100%;
  border-radius: 100%;
}

.big {
  width: 120px;
  height: 120px;
  font-size: 48px;
}

.default {
  width: 64px;
  height: 64px;
  min-width: 64px;
}

.payment {
  width: 50px;
  height: 50px;
  min-width: 50px;
}

.small {
  width: 26px;
  height: 26px;
  font-size: 10px;
  padding-bottom: unset;
  min-width: 26px;
}

.superSmall {
  width: 20px;
  height: 20px;
}

.changePhotoBtn {
  position: absolute;
  right: 0;
  bottom: 0;

  padding: 0;
  appearance: none;
  color: white;
  border-radius: 9909px;
  background: #5277FD;
  display: flex;
  flex-shrink: 0;
  border: 1px solid black;
  margin-top: 35px;
  margin-left: 65px;
}

.goToTgBtn {
  position: absolute;
  left: 0;
  bottom: 0;
  background: var(--dark-blue);
  padding: 2px;
  appearance: none;
  border-radius: 50%;
  border: none;
  display: flex;
  flex-shrink: 0;
  width: 24px;
  height: 24px;
}

.goToWABtn {
  position: absolute;
  left: 0;
  bottom: 0;
  background: var(--whatsapp);
  padding: 2px;
  appearance: none;
  border-radius: 50%;
  border: none;
  display: flex;
  flex-shrink: 0;
  width: 20px;
  height: 20px;
}

.icon {
  display: flex;
  align-items: center;
  justify-content: center;
}

.telegramIcon {
  position: absolute;
  left: 50%;
  bottom: 50%;
  width: 16px;
  height: 16px;
  transform: translate(-50%, 50%);
}
