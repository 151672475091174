.userData {
  display: flex;
  align-items: center;
  position: relative;
}

.filled {
  padding: 8px;
  background: #181818;
  border-radius: var(--border-radius);
}

.descriptionWrapper {
  margin-left: 16px;
  flex: 1;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.title {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  color: var(--white);
  font-weight: 600;
  font-size: 32px;
  line-height: 42px;
}

.info {
  margin-top: 8px;
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  gap: 8px;
}


.registerDays {
  color: var(--white-op60);
  font-size: 12px;
}

.icon {
  display: flex;
  align-items: center;
  justify-content: center;
}

.description {
  overflow-wrap: anywhere;
}
