.wrap {
  position: relative;
}

.input {
  width: 100%;
  background: transparent;
  outline: none;
}

.options {
  position: absolute;
  top: 42px;
  right: 0;
  word-wrap: break-word;
  min-width: 160px;
  max-width: 300px;
  max-height: 280px;
  text-align: center;
  overflow-y: auto;
  background: var(--gray1);
  border-radius: var(--border-radius);
  font-size: 18px;
  line-height: 22px;
}

.option {
  position: relative;
  padding: 8px;

  &:not(:last-child) {
    border-bottom: 1px solid var(--gray2);
  }
}

.icon {
  position: absolute;
  top: 50%;
  left: 10px;
  bottom: 0;
  padding-top: 2px;
  translate: 0 -50%;
}
