.container {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  flex-wrap: wrap;
}

.mainExchange {
  position: relative;
}


.icon {
  cursor: pointer;
  position: absolute;

  top: 50%;
  right: -48px;
  transform: translate(0, -50%);
}

.line {
  width: 250px;
  margin: 16px auto;
  border: none;
  border-bottom: 1px solid #313131;
}


.exchanges {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-wrap: wrap;
  gap: 16px;
  width: 100%;
}

.betterExchange {
  margin-top: 5px;
  text-align: center;
}
