.textarea {
  flex: 1;
  resize: none;
  padding: 5px 20px 5px 5px;;
  border: 1px solid #ccc;
  border-radius: 7px;
  outline: none;
  font-size: 16px;
  max-height: 250px;
  overflow-y: auto;
  line-height: 18px;
  transition: height 0.2s ease;
  min-height: 30px;
  height: 30px;
  background-color: transparent;
  color: inherit;
  width: 100%;
}

.emptyTextarea {
  position: absolute;
  right: 0;
  top: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 30px;
  height: 30px;
}

.textareaWrapper {
  position: relative;
  width: 100%;
  flex: 1;
  background-color: black;
  border-radius: 7px;
}

.audioRecorder {
  position: absolute;
  right: 5px;
  top: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 30px;
  height: 30px;
}

.buttonAudio {
  width: 25px;
  height: 25px;
  border: none;
  padding: 0;
  position: relative;
  background-color: transparent;
}
