.modal {
  overflow: hidden;
  scroll-behavior: auto;
  overflow-y: scroll;
  padding: 16px;
  border-radius: 40px;
  background: #222222;
  row-gap: 40px;


  &::-webkit-scrollbar {
    display: none;
  }

  -ms-overflow-style: none; /* IE and Edge */
  scrollbar-width: none; /* Firefox */
}

.title {
  font-size: 20px;
  line-height: 28px;
  font-weight: 400;
  margin: 8px 76px 0 8px;
}
