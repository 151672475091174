.wrap {
  display: flex;
  flex-direction: column;
  background: #181818;
  border-radius: var(--border-radius);
}

.empty {
  margin: auto;
}

.pagination {
  padding-bottom: 16px;
}
