.checkbox {
  display: flex;
  flex-direction: row;

  justify-content: center;
  align-items: center;
  padding: 10px;
}

.toggle {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  padding: 10px;
  //background: var(--bg-black);
  //border-radius: var(--border-radius);
  align-items: center;
}

.eventTitle {
  font-size: 18px;
  line-height: 20px;
}

.table {
  display: flex;
  flex-direction: column;
  margin-top: 10px;
}

.row {
  display: flex;
  width: 100%;
}

.cell {
  display: flex;
  padding: 5px;
}

.borderLeft {
  border-left: 1px solid rgb(255, 255, 255);
  border-left: 1px solid rgba(255, 255, 255, .2);
  -webkit-background-clip: padding-box; /* for Safari */
  background-clip: padding-box; /* for IE9+, Firefox 4+, Opera, Chrome */
}

.flexible {
  align-items: center;
  flex: 1;
}

.fixed {
  justify-content: center;
  width: 70px;
}

.translucent {
  color: rgba(255, 255, 255, .5);
}

.marginBottom {
  margin-bottom: 10px;
}
