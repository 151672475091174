.audioRecorder {
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  align-items: center;
  gap: 5px;
}

.button {
  font-size: 14px;
  width: 40px;
  height: 40px;
  border-radius: 50%;
  background-color: transparent;
  border: var(--white) 2px solid;
  padding: 0;
  position: relative;
}

.buttonIcon {
  position: absolute;
  transform: translate(50%, -50%);
  right: 50%;
  top: 50%;
}

.audioPlayer {
  display: none;
}

.loadingButton {
  background-color: transparent !important;
  border: var(--gray) 2px solid !important;
}

.spinner {
  border-top: 5px solid var(--gray);
  width: 25px;
  height: 25px;
}
