.status {
  display: flex;
  align-items: center;
  column-gap: 10px;
  padding: 5px;
  color: var(--blue);
  background: var(--blue-op20);
  border-radius: var(--border-radius);
}

.remove {
  stroke: var(--red)
}
