.container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  flex: 1;
}

.title {
  font-size: 144px;
}

.subtitle {
  margin-top: 32px;
  font-size: 32px;
}

.description {
  text-align: center;
  margin-top: 32px;
  color: var(--white-op60);
  margin-bottom: 8px;
}

.link {
  text-decoration: underline;
}

.button {
  width: 94%;
  position: fixed;
  bottom: 32px;
  left: 0;
  right: 0;
  margin: 0 auto;
}
