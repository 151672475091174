.container {
  display: flex;
  align-items: flex-end;
  position: relative;
  width: 100%;
  height: 56px;
  padding: 8px 24px 8px 24px;
  border-radius: 24px;
  border: 1px var(--white-op30) solid;

  &:focus-within {
    border-color: var(--white);
  }
}

.placeholder {
  position: absolute;
  pointer-events: none;
  color: var(--white-op30);
  transform: translate(24px, -50%);
  transition: 0.2s ease all;
  line-height: 16px;
  font-weight: 400;
  left: 0;
}

.input:focus ~ .bigPlaceholder, .smallPlaceholder {
  top: 14px;
  font-size: 12px;
  opacity: 1;
}

.bigPlaceholder {
  top: 50%;
  font-size: 14px;
}

.input {
  background: transparent;
  color: var(--white);
  font-size: 16px;
  line-height: 24px;
  font-weight: 400;
  border: 0;
  width: 100%;
  font-family: inherit;
  margin: 0;
  padding: 0;
  caret-color: var(--white);

  &:hover,
  &:focus,
  &:focus-visible {
    outline: none;
    border: none;
    margin: 0;
    padding: 0;
  }

}
