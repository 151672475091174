.info {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: 44px 0 20px;
}

.imgWrap {
  padding: 8px;
  margin-bottom: 6px;
  width: 60px;
  height: 60px;
  border-radius: 14px;
  background: transparent;
  box-shadow: 0 2px 8px var(--white-op60), 0 -2px 8px var(--white-op60);
  overflow: hidden;
}

.img {
  width: 100%;
}

.title {
  font-size: 12px;
  line-height: 14px;
  font-weight: 700;
}

.releaseInfo {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  padding: 10px 0;
}

.releaseVersion,
.releaseDate {
  font-weight: 500;
  font-size: 10px;
  line-height: 12px;
  color: var(--white);
  opacity: 0.75;
}
