.container {
  display: flex;
  flex-direction: column;
  height: 100%;
  text-align: center;
}

.heading {
  margin-top: 30px;
  font-weight: 400;
  font-size: 25px;
  line-height: 1.2;
}

.logo {
  flex: 0;
  width: 5rem;
  margin-top: 10%;
  margin-left: auto;
  margin-right: auto;
}

.strong {
  font-weight: 700;;
}

.line {
  margin: 60px -20px;
  border: none;
  border-bottom: 1px solid #313131;
}

.steps {
  width: 300px;
  text-align: left;
  margin-left: auto;
  margin-right: auto;

  & > li {
    font-size: 20px;
    font-weight: 500;
    margin-bottom: 16px;
  }
}

.strong {
  font-weight: 700;
}

.blue {
  color: var(--blue);
}

.link {
  margin-top: 70px;
  color: var(--gray);
  font-size: 20px;
  text-decoration-line: underline;
}

.icon {
  margin-bottom: -5px;
  margin-left: 8px;
}
