.qAndATitle {
  cursor: pointer;
}
.questionsAndAnswers {
  font-size: 14px;
  margin: 10px 18px;
  cursor: pointer;
  color: var(--white-op60);
}

.questionsAndAnswersList {
  display: flex;
  flex-direction: column;
  margin-top: 10px;
  gap: 5px;
}

.qAndAItem {
  background: #1a1a1a;
  border-radius: 20px;
  padding: 12px;
  flex-basis: 50%;
  max-width: 80%;
}

.answerItem {
  margin-left: auto;
}

.hidden {
  display: none;
}
