.updateButton {
  background: var(--dark-blue);
  border: none;
}

.title {
  font-size: 24px;
}

.closeButton {
  background: transparent;
  border: 1px var(--white) solid;
}

.description {
  font-size: 16px;
}

.buttons {
  display: flex;
  flex-direction: column;
  gap: 10px;
}
