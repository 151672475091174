.modalContent {
  width: 100%;
  height: 100%;
  padding: 8px;
  row-gap: 4px;
  max-width: none;
  background-color: transparent;
}
.modal {
  background: var(--black);
}

.content {
  height: 100%;
}

.buttons {
  display: flex;
  flex-direction: column;
  margin: auto 0 0;
}

.button {
  font-size: 14px;
  line-height: 16px;
  padding: 20px;
  border: none;
  background-color: var(--dark-blue);
  margin: auto auto 0;
}

.closeButton {
  background-color: transparent;
}

.headerTitle {
  line-height: 28px;
  font-size: 20px;
  padding: 22px 16px;
}
