@import 'src/app/styles/vars';

.select,
.btn,
.option {
  position: relative;
}

.btn,
.option {
  cursor: pointer;
}

.options {
  border: 0;
  border-radius: 4px;
}

.btn {
  background: transparent;
}

.options {
  width: 100%;
}

.option {
  width: 100%;
  padding: 18px 26px;
  margin-top: 4px;
  margin-bottom: 4px;
  border-radius: 24px;

  &:first-child {
    margin-top: 0;
  }

  &:last-child {
    margin-bottom: 0;
  }
}

.selectedOption {
  background-color: var(--dark-blue);
}

.scrollable {
  @include scrollbar();
  max-height: 250px;
  overflow-y: auto;
  -webkit-overflow-y: auto;
}

.modalContent.modalContent {
  max-width: calc(var(--touch-max-width) - (2 * var(--layout-margin)));
  width: calc(100% - 2 * var(--layout-margin));
  padding: 4px;
  margin: 0 auto;
  border-radius: 28px;
  background-color: #222;
  transform: none;
  left: 0;
  right: 0;
  top: auto;
  overflow: hidden;
}
