.payments {
  display: flex;
  flex-direction: column;
  row-gap: 16px;
}

.date {
  font-weight: 600;
  font-size: 24px;
}
