.wrap {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 20px;
  height: 20px;
  border: 1px solid var(--white);
  border-radius: var(--border-round);
  background: transparent;
}

.selected {
  border-color: var(--green);
  color: var(--green);
}

.disabled {
  &.wrap {
    border-color: var(--white-op60);
  }

  &.selected {
    border-color: #50a56c;
    color: #50a56c;
  }
}
