.wrap {
  display: flex;
  flex-direction: column;
  width: 100%;
}

.balanceInfo {
  display: flex;
  align-items: center;
}

.balanceTitle {
  color: var(--white);
  font-size: 16px;
  font-weight: 700;
}

.balanceWrap {
  margin-bottom: 20px;
  background: var(--bg-black);
  border-radius: var(--border-radius);
}

.title,
.btn {
  background: transparent;
  outline: none;
  cursor: pointer;
  color: var(--white);
}

.balance {
  padding: 10px 0;
}

.btn {
  padding: 10px;
}

.title {
  $padding: 12px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding-top: $padding;
  padding-bottom: $padding;
  border: none;
  text-align: start;
  cursor: pointer;
}

.icon {
  $angle: 90deg;
  display: flex;
  flex-shrink: 0;
  rotate: $angle;
  transition: 300ms;

  &.open {
    rotate: -$angle;
  }
}

.title,
.balances {
  width: 100%;
  $padding: 15px;
  padding-left: $padding;
  padding-right: $padding;
}

.balance:not(:last-child) {
  border-bottom: 1px solid var(--gray1);
}

.deligated {
  text-align: right;
  font-size: 10px;
  font-weight: 600;
  margin-bottom: 5px;
  padding-right: 25px;
}

.btns {
  display: flex;
}

.btn {
  display: flex;
  flex: 1;
  align-items: center;
  justify-content: center;
  border: 1px solid var(--blue);
  font-size: 18px;
  line-height: 22px;
  font-weight: 600;

  &:last-child {
    border-left: none;
  }

  &:first-child {
    border-bottom-left-radius: var(--border-radius);
  }

  &:last-child {
    border-bottom-right-radius: var(--border-radius);
  }
}
