.task {
  background: #1A1A1A;
  border-radius: 24px;
  padding: 16px;
  opacity: .5;
  &.opacity {
    opacity: 1;
  }
  &.completed {
    text-decoration: line-through;
  }
}
