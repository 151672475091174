.wrap {
  position: fixed;
  top: 0;
  left: 50%;
  transform: translateX(-50%);
  display: flex;
  width: 100%;
  align-items: center;
  justify-content: space-between;
  padding: 5px 8px;
  z-index: 100;
  @media (min-width: 768px) {
    max-width: var(--touch-max-width);
  }
}

.active {
  color: var(--blue);
}

.layout {
  height: calc(100% - 36px);
  margin-top: 36px;
  position: fixed;
  top: 0;
  left: 50%;
  transform: translateX(-50%);
  width: 100%;
}

.header {
  padding: 0;
}
