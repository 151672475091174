.filter {
  position: relative;
  cursor: pointer;
}

.value {
  display: flex;
  align-items: center;
  justify-content: space-around;
  column-gap: 5px;
  max-width: 120px;
  width: 100%;
  padding: 8px 16px;
  text-align: center;
  color: var(--blue);
  background: var(--blue-op20);
  outline: none;
  border: none;
  border-radius: var(--border-radius);
  font-size: 14px;
  font-weight: 500;
  cursor: inherit;
  white-space: nowrap;
}

.active {
  background: var(--blue);
  color: var(--white);
}

.options {
  position: absolute;
  top: 40px;
  right: 0;
  z-index: 10;
  background: var(--gray1);
  border-radius: var(--border-radius);
  white-space: nowrap;
}

.option {
  display: flex;
  align-items: center;
  column-gap: 16px;
  padding: 10px 20px;

  &:not(:last-child) {
    border-bottom: 1px solid var(--gray2);
  }
}
