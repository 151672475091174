@import 'src/app/styles/vars';

.container {
  width: 100%;
  padding: 10px 0;
}

.text {
  display: -webkit-box;
  overflow: hidden;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 2;
  line-clamp: 2;
  color: var(--white-op60);
  font-size: 18px;
  line-height: 22px;
}

.iconWrapper {
  padding: 15px;
  position: relative;
}

.icon {
  position: absolute;
  stroke: var(--red);
  opacity: .5;
  top: 50%;
  right: 50%;
  transform: translate(50%, -50%);
}

.modal {
  background: none;
  -webkit-backdrop-filter: none;
  backdrop-filter: none;
}

.modalContent {
  max-width: calc(var(--touch-max-width) - (2 * var(--layout-margin)));
  width: calc(100% - 2 * var(--layout-margin));
  padding: 4px;
  margin: 0 auto;
  border-radius: 16px;
  background-color: #333333;
  transform: none;
  left: 0;
  right: 0;
  top: auto;
  overflow: hidden;
}

.option {
  width: 100%;
  padding: 0 10px;
  display: flex;
  justify-content: space-between;
  border-bottom: 1px rgba(255, 255, 255, 0.1) solid;

  &:first-child {
    margin-top: 0;
  }

  &:last-child {
    margin-bottom: 0;
    border: none;
  }
}

.scrollable {
  @include scrollbar();
  max-height: 200px;
  overflow-y: auto;
  -webkit-overflow-y: auto;
}
