.emoji {
  display: flex;
  flex-direction: row;
  gap: 5px;
  font-size: 20px;
}

.opacity {
  opacity: .5;
}
