.empty {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
}

.assistants {
  display: flex;
  flex-direction: column;
  row-gap: 10px;
}

.loading {
  display: flex;
  flex-wrap: nowrap;
  gap: 16px;
}

.header {
  font-size: 20px;
  font-weight: 600;
  margin-bottom: 10px;
}

.balance {
  margin-bottom: 20px;
}

.wrap {
  margin-bottom: 20px;
}

.search {
  margin-bottom: 10px;
}
