.tabsContainer {
  display: flex;
  align-content: center;
  margin: 0 0 10px;
  background: var(--gray1);
  border-radius: var(--border-radius);
  :focus-visible {
    outline: none;
  }
}

.panels {
  height: 100%;
  overflow-y: auto;
}
