.backButton {
  display: flex;
  align-items: center;
  column-gap: 5px;
  max-width: fit-content;
  font-size: 18px;
  line-height: 1;
  color: var(--blue);
  background: transparent;
  outline: none;
  border: none;
  cursor: pointer;
  padding: 0;
}

.icon {
  display: flex;
  flex-shrink: 0;
}
