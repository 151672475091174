.rateInput {
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
  padding: 0px 8px;
  margin-right: 48px;
}

.inputWrapper {
  display: block;
}

.input {
  margin: 0 2px;
  padding: 0;
  color: var(--white);
  background: none;
  border: none;
  text-align: center;
  width: 100%;
  min-width: 43px;
}

.input::-webkit-outer-spin-button,
.input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

.input[type=number] {
  -moz-appearance: textfield;
}

.large {
  font-size: 20px;
  letter-spacing: 0.025em;
  line-height: 120%;
  max-width: 90px;
}

.holder {
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 5px;
  border-bottom: 1px solid var(--white);
  cursor: default;

  &:after {
    content: '%';
  }
}

.default {
  max-width: 50px;
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 130%;
  letter-spacing: -0.03em;
}

.icon {
  display: flex;
  flex-shrink: 0;
}
