.title {
  display: flex;
  justify-content: space-between;
}

.button {
  appearance: none;
  display: flex;
  align-items: center;
  justify-content: center;
  background: none;
  border: none;
}

.icon {
  height: 32px;
  width: 32px;
  color: var(--white);
}

.buttonsBlock {
  display: flex;
  flex-direction: row;
}

.search {
  margin-top: 8px;
}

.displayNone {
  display: none;
}
