.form {
  display: flex;
  flex-direction: column;
  row-gap: 16px;
}

.button {
  transition: 300ms;
}

.success:disabled {
  background: var(--green);
  border-color: var(--green);
  color: var(--black);
}
