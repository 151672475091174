.background {
  width: 100%;
  min-height: 18px;
  background-position: top center;
  background-repeat: no-repeat;
  background-size: cover;
  background-image: url('./images/task_placeholder.svg');
}

.mainTask {
  background-color: var(--white);
  border-radius: 30px;
  width: 100%;
  padding: 20px;
  display: flex;
  flex-direction: column;
}

.mainTaskTitle {
  font-weight: 600;
  font-size: 24px;
  color: var(--black);
  margin-bottom: 8px;
}

.mainTaskDescription {
  font-weight: 400;
  font-size: 14px;
  line-height: 135%;
  color: #6D6D6D;
}

.mainTaskButton {
  color: var(--white);
  background-color: var(--black);
  border-radius: 12px;
  height: 39px;
  font-size: 14px;
  line-height: 135%;
  letter-spacing: -0.24px;
  font-weight: 400;
  padding: 10px 25px;
  margin: 24px auto 0;
}

.aiPrioritized {
  display: flex;
}

.aiPrioritizedIcon {
  margin: 3px 10px 3px 0;
}

.aiPrioritizedTitle {
  font-weight: 400;
  font-size: 10px;
  line-height: 14px;
  color: #6D6D6D;
}

.aiPrioritizedDescription {
  font-weight: 600;
  line-height: 135%;
  font-size: 14px;
  color: var(--black);
}

.header {
  display: flex;
  justify-content: space-between;
  margin-bottom: 15px;
}
