.navTabsList {
  display: flex;
}

.navTabsListItem {
  flex: 1 1 auto;
  text-align: left;
  padding: 16px 10px 14px;
  margin-bottom: 8px;
  background: transparent;
  cursor: pointer;
  transition: color .15s ease-in-out, background-color .15s ease-in-out, border-color .15s ease-in-out;
  outline: none;
  border: none;
  appearance: none;
  font-size: 14px;
  line-height: 16px;
  color: var(--white-op60);
  border-bottom: 2px var(--white-op60) solid;


  &.active {
    color: var(--white);
    border-bottom: 2px var(--white) solid;
  }
}

.navTabsPanel {
  height: 100%;
  display: flex;
  flex-direction: column;
  overflow: auto;
  outline: none;
}

.button {
  min-width: 113px;
  height: 32px;
  border: 1px var(--white) solid;
  border-radius: 10px;
  font-size: 14px;
  line-height: 16px;
  color: var(--white);
  font-weight: 400;
  padding: 8px 16px;
}

.blockTitle {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  padding: 24px 4px;
}

.aiPrioritized {
  display: flex;
  align-items: center;
  gap: 8px;
}

.aiPrioritizedText {
  font-size: 16px;
  line-height: 135%;
}

.tasks {
  display: flex;
  flex-direction: column;
  gap: 4px;
  &.topPadding {
    padding-top: 24px;
  }
}

.placeholder {
  text-align: center;
  margin-top: 40px;
  opacity: .5;
}
