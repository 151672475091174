.btn {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 70px;
  height: 70px;
  color: var(--green);
  border: 3px solid var(--green);
  border-radius: var(--border-round);
}

.icon {
  transform: scale(1.5);
}
