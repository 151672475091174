*,
*::after,
*::before {
  box-sizing: border-box;
}

body,
html {
  background-color: var(--black);
  color: var(--white);
  padding: 0;
  margin: 0;
  height: 100vh;
  height: var(--doc-height);
}

html {
  overflow: hidden;
}

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
  'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
  sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  overflow: auto;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
  monospace;
}

#root {
  height: 100%;
  min-width: 320px;
}

p {
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 130%;
  letter-spacing: -0.03em;
  color: var(--white-op60);
}

h1 {
  font-style: normal;
  font-weight: 600;
  font-size: 32px;
  line-height: 130%;
  letter-spacing: 0.02em;
  user-select: none;
}

h2 {
  font-weight: 600;
  font-size: 24px;
}

a {
  color: inherit;
  text-decoration: none;
}

b {
  font-style: normal;
  font-weight: 700;
  font-size: 16px;
  line-height: 120%;
}

.errorContainer {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  flex: 1;
  gap: 32px;
}
