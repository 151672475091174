.success {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  text-align: center;
  margin-top: 20px;
}

.successTitle {
  margin-bottom: 20px;
}

.iconWrap {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100px;
  height: 100px;
  margin-bottom: 20px;
  border: 2px solid;
  border-radius: var(--border-round);
  stroke: var(--black);
  background: var(--white);
  border-color: var(--white);
}

.button {
  color: var(--white);
  font-weight: 400;
  border-radius: 24px;
  background-color: var(--dark-blue);
  min-height: 56px;
  margin: auto 12px 14px;
  font-size: 14px;
  line-height: 16px;
}
