.modal {
  max-height: 50%;
  overflow: hidden;
  scroll-behavior: auto;
  overflow-y: scroll;

  &::-webkit-scrollbar {
    display: none;
  }
  -ms-overflow-style: none; /* IE and Edge */
  scrollbar-width: none; /* Firefox */
}

.balances > li {
  margin: 10px auto;

  &:first-child {
    margin-top: 0;
  }
  &:last-child {
    margin-bottom: 10px;
  }
}

.error {
  border-radius: 20px;
  border: 2px solid red;
  height: 60px;
  color: red;
  padding: 5px;
}
