.wrap {
  display: flex;
  flex-direction: column;
  height: 100%;
}

.user {
  margin-bottom: 5px;
}

.resellStrategy {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 20px;
  padding: 10px;
  color: var(--white-op60);
  background: #181818;
  border-radius: var(--border-radius);
}

.selectButton {
  color: var(--light-blue);
  padding: 8px;
  padding-right: 17px;
}

.selectOptions {
  min-width: 250px;
}

.title {
  margin-bottom: 10px;
  font-weight: 600;
  font-size: 15px;
  line-height: 18px;
}

.empty {
  margin: auto;
}

.providers {
  display: flex;
  flex-direction: column;
  row-gap: 10px;
  height: 100%;
}
