.container {
  display: flex;
  align-items: center;
  position: relative;
  gap: var(--gap);
}

.info {
  display: flex;
  flex-direction: column;
  gap: 5px;
  align-items: flex-start;
}

.balanceName {
  font-size: 14px;
  font-weight: 600;
  line-height: 17px;
}
