.title {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 24px 4px;
  font-size: 20px;
  font-weight: 500;
  letter-spacing: -0.24px;

  button {
    font-size: 14px;
    line-height: 16px;
  }
}

.task {
  display: flex;
  align-items: center;
  padding: 16px;
  background-color: #1a1a1a;
  border-radius: 24px;
  margin-bottom: 4px;
}

.taskText {
  display: block;
  color: rgba(255, 255, 255, 0.5);
  font-size: 14px;
  font-weight: 400;
  line-height: 16px;
  flex-grow: 1;
  padding-right: 10px;
}

.button {
  display: flex;
  padding: 8px 16px;
  justify-content: center;
  align-items: center;
  width: auto;
  background-color: transparent;
  color: var(--white);

  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: 16px;

  border-radius: 24px;
  border: 1px solid rgba(255, 255, 255, 0.3);
}

.taskStatus {
  width: 6px;
  height: 6px;
  border-radius: 50%;
  margin-right: 5px;
}

.red {
  background-color: var(--red-new);
  box-shadow: 0 0 2px 1px var(--red-new);
}

.yellow {
  background-color: var(--yellow-new);
  box-shadow: 0 0 2px 1px var(--yellow-new);
}

.green {
  background-color: var(--green-new);
  box-shadow: 0 0 2px 1px var(--green-new);
}
