.section {
  display: flex;
  flex-direction: column;
  gap: 10px;
  padding: 20px 16px;
  background: #1A1A1A;
  border-radius: 28px;
}

.taskTitle {
  font-size: 24px;
  font-weight: 600;
}

.sectionTitle {
  font-size: 12px;
  color: var(--white-op60);
}

.sectionDescription {
  font-size: 16px;
  line-height: 135%;
}

.task {
  display: flex;
  flex-direction: column;
  gap: 4px;
  margin-top: 70px;
}

.sectionHeader {
  display: flex;
  flex-direction: row;
}

.aiIcon {
  width: 15px;
  height: 15px;
  margin-left: 11px;
  margin-right: 6px;
}

.aiProposed {
  font-size: 11px;
  line-height: 135%;
  font-weight: 600;
}
