.sticky {
  position: sticky;
  bottom: 0;
  left: 0;
  right: 0;
  z-index: 5;
  background: var(--black);
  margin-top: auto;
}

.bottomPadding {
  padding-bottom: 16px;
}

.button {
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 16px 20px;
  border: none;
  border-radius: var(--border-radius);
  color: var(--white);
  cursor: pointer;
  outline: none;
  font-size: 20px;
  flex: 1 1 auto;

  &:disabled {
    border: none;
    background-color: var(--blue-opt20);
  }
}

.actionList {
  display: flex;
  flex-wrap: wrap;
  gap: 10px;
}

.contained {
  background-color: var(--blue);
}

.outlined {
  background-color: transparent;
  border: 1px solid var(--blue);
}

.secondary {
  border: 1px solid var(--blue);
  border-radius: 8px;
  background: var(--black);
}
