%text {
  font-weight: 700;
  font-size: 16px;
  line-height: 20px;
  color: var(--white);
}

.container {
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.title {
  @extend %text;
  margin-bottom: 16px;
  text-align: center;
}

.video {
  display: block;
  margin: 0 auto;
  height: 80%;
}

.description {
  position: relative;
  display: flex;
  align-items: center;
  column-gap: 4px;
  margin: 0 auto;
}

.tapOnShare {
  @extend %text;
  color: var(--white);
  font-weight: 700;
}

.back {
  text-align: center;
  text-decoration: underline;
}
