:root {
  --gap: 8px;
}

.bigGap {
  --gap: 10px;
}

.defaultGap {
  --gap: 8px;
}

.paymentGap {
  --gap: 5px;
}

.smallGap {
  --gap: 3px;
}

.container {
  display: flex;
  align-items: center;
  position: relative;
  gap: calc(var(--gap) * 2);
}

.container.reverse {
  flex-direction: row-reverse;
  align-items: flex-end;
  justify-content: space-between;
}

.descriptionWrapper {
  margin-left: calc(var(--gap) * 2);
  flex: 1;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.title {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  color: var(--white);
  font-weight: 600;
  font-size: 32px;
  line-height: 42px;
}

.infoWrap {
  display: flex;
  flex-direction: column;
  gap: var(--gap);
  align-items: flex-start;
}

.usernameWrap {
  display: flex;
  align-items: flex-start;
}

.name {
  overflow-wrap: anywhere;
}

.nameField,
.changeNameBtn {
  border: none;
  outline: none;
  appearance: none;
  background: none;
  padding: 0;
}

.nameField {
  color: currentColor;
  font-size: 16px;
  line-height: 1;
  outline: none;
}

.changeNameBtn {
  color: #4866EC;
  background: rgba(82, 119, 253, 0.23);
  border-radius: 9999px;
  margin-left: 0.5em;
  flex-shrink: 0;
}

.info {
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  gap: var(--gap);
}

.registerDays {
  color: var(--white-op60);
  font-size: 12px;
}

.icon {
  display: flex;
  align-items: center;
  justify-content: center;
}

.avatar {
  flex-shrink: 0;
}