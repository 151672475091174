.content {
  position: relative;
}

.iframe {
  width: 100%;
  height: 100%;
}

.goBackButton {
  position: absolute;
  width: 24px;
  height: 24px;
  top: 0;
  right: 0;
  margin-top: 15px;
  margin-right: 15px;
  background: transparent;
  border: 0;
}

.notFound {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 100%;
  text-align: center;
}
