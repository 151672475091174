.assistant {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.removeAssistantIconContainer {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 20px;
  height: 20px;
  border-radius: var(--border-round);
  background: var(--gray3);

}

.removeAssistantIcon {
  stroke: var(--black);
}
