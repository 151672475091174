.modal {
  background-color: rgba(24, 24, 24, 0.5);
}

.modalContent {
  background-color: var(--black);
  border-radius: 20px;
  padding: 8px;
  max-width: 430px;
}

.description {
  font-size: 18px;
  line-height: 135%;
  margin-left: 18px;
  margin-top: 10px;
}

.error {
  color: var(--red);
}

.confirmButton {
  color: var(--white);
  font-weight: 400;
  border: none;
  border-radius: 24px;
  background-color: var(--dark-blue);
  min-height: 56px;
  margin-bottom: 14px;
  font-size: 14px;
  line-height: 16px;
}

.topButton {
  height: 32px;
  border: 1px var(--white) solid;
  border-radius: 10px;
  font-size: 14px;
  line-height: 16px;
  color: var(--white);
  font-weight: 400;
  padding: 8px 16px;
  margin: 24px 10px 0 auto;
}

.closeButton {
  font-size: 16px;
  background: transparent;
}

.buttons {
  display: flex;
  flex-direction: column;
  gap: 10px;
}

.comment {
  outline: none;
  background: transparent;
  min-height: 148px;
  border-radius: 24px;
  border: 1px #CFDCE8 solid;
  padding: 20px;
  color: var(--white);
  font-family: inherit;
  font-size: 16px;
  line-height: 18px;
  transition: border 300ms;
  resize: none;
}
