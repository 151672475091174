.row {
  display: grid;
  background: transparent;

  & > :not(:last-child) {
    border-right: 1px solid var(--gray3);
  }

  & > :first-child {
    text-align: left;
  }

  & > :not(:first-child) {
    text-align: center;
  }

}
