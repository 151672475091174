.role {
  padding: 3px 8px;
  font-size: 12px;
  border-radius: 2px;
  text-align: center;
  white-space: nowrap;
}

.default {
  background-color: var(--gray);
}

.provider {
  background-color: var(--green-op20);
  color: var(--green);
}
