.wrap {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-bottom: 16px;
}

.rideTitle {
  font-size: 30px;
  line-height: 36px;
}

.title {
  font-weight: 700;
}

.inner {
  color: var(--green);
}

.text {
  color: var(--green);
  font-size: 12px;
  text-align: center;
  width: 250px;
}
