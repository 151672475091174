.field {
  width: 100%;
  border-radius: 28px;
  background: #222;
  padding: 24px 16px 16px;
}

.fieldZ {
  z-index: 150;
}

.col {
  width: 100%;
}

.row {
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  column-gap: 8px;
}

.titleWrap {
  flex-wrap: nowrap;
}

.title {
  opacity: 0.5;
  color: white;
  width: 100%;
  height: 16px;
  font-size: 14px;
  font-weight: 400;
  line-height: 16px;
  letter-spacing: 0;
  text-align: left;
}

.iconContainer {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 20px;
  height: 20px;
  border-radius: var(--border-round);
  background-color: rgba(82, 119, 253, 0.23);
  color: #4866EC;
  cursor: pointer;
}
