.label {
  display: flex;
  align-items: center;
  user-select: none;
}

.input {
  opacity: 0;
  width: 0;
  height: 0;
}

.checkbox {
  display: flex;
  cursor: pointer;
  background: transparent;
  border: 1px solid var(--white);
  transition: 0.2s;
  align-items: center;
  justify-content: center;
  border-radius: 5.33px;
  width: 16px;
  height: 16px;
  flex-shrink: 0;
}

.icon {
  fill: var(--white);
}
