.spinner {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.friend {
  display: flex;
  flex-direction: column;
  height: 100%;
}

.user {
  position: relative;
  height: 60px;
  width: 100%;
  background-color: var(--black);
  justify-content: space-between;

  display: flex;
  align-items: center;
  // position: relative;
  gap: calc(var(--gap) * 2);

  @media (min-width: 768px) {
    max-width: calc(var(--touch-max-width) - var(--layout-margin) * 2);
  }
}

.userInfo {
  display: flex;
  flex-direction: column;
  row-gap: 2px;
}

.date {
  text-align: center;
  font-weight: 500;
}

.hideIcon {
  margin-left: auto;
  color: var(--white);
  width: 24px;
  height: 24px;
}

.bottomPanel {
  position: static;
  bottom: 0;
  background-color: var(--black);
  width: 100%;
  padding-bottom: 30px;
  display: flex;
  flex-direction: column;
  gap: 10px;
  align-items: flex-start;

  @media (min-width: 768px) {
    max-width: calc(var(--touch-max-width) - var(--layout-margin) * 2);
  }
}

.bottomPanelFixed {
  position: fixed;
  bottom: 0;
  left: 0;
  width: 100%;
  /* Другие необходимые стили */
}

.bottomPanelStatic {
  position: inherit; /* или block, если требуется */
  /* Стили для интеграции панели в поток страницы */
  width: 100%;
}

.inputWrapper {
  flex-grow: 1;
}

.button {
  position: relative;
  width: 30px;
  border-radius: 50%;
  height: 30px;
  padding: 0;
}

.motivation {
  display: flex;
  flex-direction: column;
  gap: 3px;
}

.motivationName {
  font-size: 13px;
  color: var(--gray3);
}

.motivations {
  display: flex;
  flex-direction: column;
  position: fixed;
  top: 106px;
  gap: 10px;
  z-index: 1;
  background: var(--gray1);
  border-radius: 20px;
  padding: 15px;
  width: calc(100% - var(--layout-margin)* 2);
  @media (min-width: 768px) {
    max-width: calc(var(--touch-max-width) - var(--layout-margin)* 2);
  }
}

.closeMotivation {
  position: absolute;
  right: 0;
  top: 0;
  width: 40px;
  height: 40px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.hidden {
  display: none;
}

.blockUserInfo {
  display: flex;
  flex-direction: row;
  gap: 10px;
  align-items: center;
}

.blockStatusInfo {
  display: flex;
  flex-direction: row;
  gap: 10px;
}

.textarea {
  flex: 1;
  resize: none;
  padding: 5px;
  border: 1px solid #ccc;
  border-radius: 7px;
  outline: none;
  font-size: 16px;
  max-height: 100px;
  overflow-y: auto;
  line-height: 18px;
  transition: height 0.2s ease;
  min-height: 30px;
  height: 30px;
  background-color: transparent;
  color: inherit;
}

.textarea::-webkit-resizer {
  display: none;
}

.arrowIcon {
  position: absolute;
  width: 30px;
  height: 30px;
  left: 50%;
  bottom: 50%;
  transform: translate(-50%, 50%);
}

.emptyTextarea {
  position: absolute;
  right: 40px;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 30px;
  height: 30px;
}

.bottomInputBlock {
  display: flex;
  flex-direction: row;
  gap: 10px;
  width: 100%;
  align-items: flex-end;
}

.blockText {

}

.blockMessage {
  padding-left: 40px;
}

.fileInput {
  margin-top: 10px;
}

.fileList {
  display: flex;
  flex-direction: column;
  gap: 10px;
  max-height: 100px;
  overflow-y: auto;
}

.fileItem {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  gap: 10px;
  padding: 10px;
  border-radius: 10px;
  background-color: var(--gray1);
  max-height: 40px;
  position: relative;
}

.fileRemoveButton {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 30px;
  height: 30px;
  position: absolute;
  top: 50%;
  right: 10px;
  transform: translate(0, -50%);
}

.blockAttachments {
  display: flex;
  flex-direction: column;
  width: 100%;
}

.fileInputWrapper {
  position: relative;
  display: inline-block;
}

.fileInput {
  display: none;
}

.fileLabel {
  display: inline-block;
  width: 30px;
  height: 30px;
}

.fileInputPlus {
  display: flex;
  justify-content: center;
  align-items: center;
}

.plusIcon {
  width: 30px;
  height: 30px;
}

.inputsBlock {
  display: flex;
  flex-direction: column;
  gap: 10px;
  width: 100%;
}

.messageList {
  flex: 1;
  overflow-y: auto;
  margin: 0;
  padding: 0;
  display: flex;
  flex-direction: column;
  gap: 15px;
}

.messageList li:last-child {
  margin-bottom: 20px;
}

.textareaContainer {
  display: flex;
  flex-direction: column;
  width: 100%;
  position: relative;
  flex: 1 1;
}

.followupBadge {
  background: var(--blue);
  width: fit-content;
  border-top-left-radius: 7px;
  border-top-right-radius: 7px;
  padding: 4px 6px 10px;
  position: absolute;
  top: -18px;
  left: 0;
  font-size: 11px;
}
