.row {
  border-bottom: 1px solid var(--gray2);
}

.row td {
  padding: 5px 0;

  &:first-child {
    padding-left: 8px;
  }

  &:last-child {
    padding-right: 8px;
  }
}

.code {
  font-size: 15px;
  line-height: 19px;
}

.method {
  display: flex;
  align-items: center;
  gap: 5px;
  color: var(--gray3);
}

.methodIcon {
  color: var(--green);
}

.exchange {
  width: 30px;
  vertical-align: middle;
  color: var(--blue);
}

.rate {
  vertical-align: middle;
  color: var(--green);
}

.checkCell {
  vertical-align: middle;
}

.btn {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 0;
  outline: none;
  border: none;
  background: transparent;
  margin: 0 auto;
}
