.userPhoto {
  min-width: 48px;
  min-height: 48px;
  border-radius: 10px;
  background: #1B1B1B center;
  background-size: cover;
  margin-right: 12px;
}

.userInfo {
  display: flex;
  flex-direction: row;
  align-items: center;
}

.nameAndPhoto {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  width: 100%;
}

.goToTgBtn {
  background: var(--dark-blue);
  padding: 2px;
  appearance: none;
  border-radius: 50%;
  border: none;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-shrink: 0;
  width: 35px;
  height: 35px;
}

.telegramIcon {
  width: 20px;
  height: 20px;
}
