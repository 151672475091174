@font-face {
  font-family: "DM Sans";
  font-style: normal;
  font-weight: 700;
  font-display: swap;
  src: url("fonts/dm-sans/DMSans-Bold.ttf") format('woff');
}

@font-face {
  font-family: "DM Sans";
  font-style: normal;
  font-weight: 500;
  font-display: swap;
  src: url("fonts/dm-sans/DMSans-Medium.ttf") format('woff');
}

@font-face {
  font-family: "DM Sans";
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: url("fonts/dm-sans/DMSans-Regular.ttf") format('woff');
}
