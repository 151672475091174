.container {
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 6px;
}

.avatar {
  @media screen and (max-width: 389px) {
    display: none;
  }
}
.payment {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

.paymentInfo {
  width: 100%;
}

.telegramButton {
  display: flex;
  align-items: center;
  justify-content: center;
  background: var(--dark-blue);
  padding: 8px;
  border-radius: 50%;
  margin: auto 0;
}

.telegramIcon {
  width: 20px;
  height: 20px;
}
