@import 'src/app/styles/vars';

.layout {
  display: flex;
  flex-direction: column;
  height: 100%;
  color: var(--white);
  overflow: hidden;
}

.plaidContent {
  @include content-container();
  padding: 0 20px;
  font-family: 'DM Sans', sans-serif;

  @media screen and (min-width: 768px) {
    max-width: 1180px;
  }
}
