.success {
  display: flex;
  flex-direction: column;
  width: 100%;
  gap: 40px;
}

.title {
  font-size: 20px;
  line-height: 28px;
  font-weight: 400;
  margin: 8px 8px 0 8px;
}

.description {
  margin: 0 8px;
  font-size: 14px;
  line-height: 16px;
  color: var(--white);
}

.buttons {
  display: flex;
  flex-direction: column;
  gap: 4px;
}

.button {
  border-radius: 24px;
  padding: 20px 16px 20px 16px;
  border: 0;
  font-size: 14px;
  line-height: 16px;
  font-weight: 400;
  position: relative;
  text-align: center;
}

.instButton {
  background: radial-gradient(135.36% 2643.25% at -35.36% 0%, #FFDD55 0%, #FFDD55 10%, #FF543E 50%, #C837AB 100%);
}

.xButton {
  background: var(--black);
}

.closeButton {
  width: 28px;
  height: 28px;
  position: absolute;
  top: 16px;
  right: 16px;
  background: transparent;
  border: 0;
  margin: 8px 8px 0 0;
  padding: 0;
}

.closeIcon {
  width: 10px;
  height: 10px;
}

.buttonIcon {
  position: absolute;
  left: 19px;
  top: 19px;
}
