.container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  flex: 1;
  margin-top: 64px;
}

.title {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100px;
  height: 100px;
  background-color: var(--red);
  border-radius: var(--border-round);
  font-size: 68px;
}

.subtitle {
  margin-top: 32px;
  font-size: 32px;
  text-align: center;
}

.description {
  text-align: center;
  margin-top: 16px;
  font-size: 16px;
  color: var(--white-op60);
}

.link {
  text-decoration: underline;
  border: none;
  font-size: 16px;
  color: var(--white-op);
  background-color: var(--gray-op);
  margin-top: 32px;
  cursor: pointer;
}

.button {
  width: 94%;
  position: fixed;
  bottom: 32px;
  left: 0;
  right: 0;
  margin: 0 auto;
}
