.usersWrapper {
    display: flex;
    flex-wrap: nowrap;
    justify-content: space-between;
    margin-bottom: 10px;
}

.userCard {
    margin-bottom: 10px;
}

.userInfo {
    display: flex;
    flex-direction: column;
    width: 48%;
}

.inline {
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.text {
    font-size: 12px;
}

.amount {
    margin-right: 5px;
}

.info {
    display: flex;
    align-items: flex-start;
}

.sticker {
    border-radius: 4px;
    font-size: 10px;
    line-height: 10.6px;
    padding: 3px 5px;
    margin-top: 3px;
    border: none;
    appearance: none;
    text-indent: 1px;
    background: var(--blue-op20);
    color: var(--blue);
}

.divider {
  width: 10%;
  background: var(--gray);
  height: 1px;
  margin: 10px auto;
}

.feeWrapper {
  padding: 10px;
  background-color: var(--gray-op40);
  border-radius: 5px;
}
